
.flag {
    &-warehouse {
        position: relative;
        display: inline-block;
        z-index: 1;
        background: $g_blue;
        line-height: 22px;
        padding: 5px 12px;
        text-transform: uppercase;
        margin-bottom: 16px;

        &::after {
            position: absolute;
            z-index: -1;
            top: 1px;
            right: 1px;
            bottom: 1px;
            left: 1px;
            content: ' ';
            background-color: $c_white;
        }

        &--placeholder {
            background: transparent;

            @media (max-width: $screen-sm-max) {
                display: none;
            }
        }

        &--redesign {
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            background: $c_paleslate;
            margin-bottom: 10px;

            &::after {
                content: none;
            }
        }
    }
}

.new-item {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    padding: 8px;
    color: $c_white;

    &.blue-gradient-bg {
        background: $g_blue;
    }
}
