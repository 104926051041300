@import 'config/init';

$animationSpeed: 250ms;

// Atom Forms
.form {
    position: relative;
}

.bootstrap-select {
    position: relative;
    padding: 0;
    transition: all $animationSpeed ease-in-out;
    border-bottom: 0;
    width: 100% !important;

    &::before {
        right: 7px;
        transform: rotate(36deg);

        [dir='rtl'] & {
            right: auto;
            left: 7px;
        }
    }

    &::after {
        right: 0;
        transform: rotate(-36deg);

        [dir='rtl'] & {
            right: auto;
            left: 0;
        }
    }

    &::after,
    &::before {
        width: 10px;
        height: 1px;
        background: #000;
        content: '';
        position: absolute;
        z-index: 5;
        margin-top: 23px;
        margin-right: 10px;
        transition: all $animationSpeed;

        [dir='rtl'] & {
            margin-right: 0;
            margin-left: 10px;
        }
    }

    .dropdown-toggle {
        background: transparent;
        transition: all $animationSpeed ease-in-out;
        border: none;
        height: 48px;
        width: 100%;
        padding-right: 5px;
        color: $c_black;
        font-weight: 400;
        position: relative;
        overflow: hidden;
        border-bottom: 1px solid #e6e6e6;

        &:focus {
            border-bottom: 1px solid #e6e6e6;
            outline: none;
        }

        &:hover::before {
            background: #f7f7f7;
        }

        &::before {
            width: 100%;
            height: 100%;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            transition: all $animationSpeed ease;
            background: transparent;
        }

        .bs-caret {
            right: 0;
            position: absolute;

            [dir='rtl'] & {
                left: 0;
                right: auto;
            }
        }

        .bs-caret .caret {
            border: 0;
        }

        .filter-option {
            z-index: 3;
            position: absolute;
            width: auto !important;
            left: 15px;
            right: 35px;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: left;
            transition: transform $animationSpeed ease, opacity 5s ease;

            @include setDirection(ltr);

            [dir='rtl'] & {
                right: 15px;
                left: 35px;
            }
        }
    }

    &.open .btn,
    &.open .btn:focus,
    &.open .btn:hover,
    .btn,
    .btn:focus,
    .btn:hover {
        background: transparent;
    }

    &.open .btn {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    &.open .btn::before {
        transition: all $animationSpeed ease;
        transform: translate(0, 140px);
    }

    &.open .btn span {
        transition: transform $animationSpeed ease, opacity 5s ease;
        transform: translate(0, 140px);
        opacity: 0;
    }

    &.open .btn::after {
        z-index: 4;
    }

    .dropdown-menu {
        display: block;
        width: 100%;
        padding: 0;
        box-shadow: none;
        border-left: 0;
        border-right: 0;
        border-top: 0;
        border-bottom: 0;
        margin-top: 0;
        margin-left: -1px;
        transition: all $animationSpeed ease;
        height: 0;
        max-height: none;
        min-height: 0 !important;
        overflow: hidden;
        z-index: 999;

        > li > a {
            white-space: normal;
        }
    }

    .dropdown-menu.inner {
        position: static;
        float: none;
        border: 0;
        padding: 0;
        margin: 0;
        border-radius: 0;
        box-shadow: none;
        border-bottom: 0;
    }

    .dropdown-menu.inner > li {
        cursor: pointer;
    }

    .dropdown-menu.inner > li a {
        padding: 10px 20px;
    }

    .dropdown-menu.inner > li a:hover,
    .dropdown-menu.inner > li.selected a {
        color: #333333;
        background: #f7f7f7;
    }

    &.open::before {
        transform: rotate(-36deg);
    }

    &.open::after {
        transform: rotate(36deg);
    }

    &.open .dropdown-menu {
        transition: all $animationSpeed ease;
        max-height: none;
        min-height: 0;
        height: 80px; // für dropdown animation muss eine fixe Höhe gesetzt werden z.B.: 80px
        border-bottom: 1px solid #e6e6e6;
    }

    &--gray {
        .dropdown-toggle {
            &::before {
                background-color: $c_lightgray25;
            }
        }
    }
}

.bs-select-hidden {
    display: none !important;
}

.dropdown {
    position: relative;
    padding: 0;
    transition: all $animationSpeed ease-in-out;
    border-bottom: 0;
    width: 100% !important;

    &::after,
    &::before {
        position: absolute;
        width: 10px;
        height: 1px;
        background: $c_black;
        content: '';
        z-index: 5;
        margin-top: 23px;
        margin-right: 10px;
        transition: all $animationSpeed ease-in-out;
    }

    &::before {
        right: 7px;
        transform: rotate(36deg);

        [dir='rtl'] & {
            right: auto;
            left: 7px;
        }
    }

    &::after {
        top: 0;
        right: 0;
        transform: rotate(-36deg);

        [dir='rtl'] & {
            right: auto;
            left: 0;
        }
    }

    .dropdown-toggle {
        position: relative;
        overflow: hidden;
        border: none;
        height: 48px;
        width: 100%;
        padding-right: 25px;
        font-weight: 400;
        border-bottom: 1px solid $c_lightgray50;
        text-align: left;
        font-weight: 700;
        color: $c_black;
        background: transparent;
        transition: all $animationSpeed ease-in-out;

        &:hover {
            background: $c_lightgray15;
            border-bottom: 1px solid $c_lightgray25;
            outline: none;
        }

        &::before {
            width: 100%;
            height: 100%;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            transition: all $animationSpeed ease;
            background: transparent;
        }

        > div {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            span {
                color: $c_gray75;
            }
        }

        [dir='rtl'] & {
            text-align: right;
            padding-right: 15px;
            padding-left: 25px;
        }
    }

    .dropdown-menu {
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        max-height: 0;
        overflow: hidden;
        transition: all $animationSpeed ease;
        box-shadow: none;
        border: none;

        // @media (max-width: $screen-sm-max) {
        //     position: static;
        // }

        > li {
            cursor: pointer;

            > * {
                padding: 10px 20px;
                white-space: normal;
            }
        }

        .view_more {
            div {
                cursor: pointer;
                color: $c_darkblue;
            }

            i {
                font-size: $fs16;
            }
        }
    }

    &.open {
        &::before {
            transform: rotate(-36deg);
        }

        &::after {
            transform: rotate(36deg);
        }

        .btn {
            box-shadow: none;

            &::before {
                transition: all $animationSpeed ease;
                transform: translate(0, 140px);
            }
        }

        .dropdown-menu {
            transition: all $animationSpeed ease;
            max-height: 1000px;
            border-bottom: 1px solid $c_lightgray25;
        }
    }
}

.dropdown-accordion .panel-title a {
    position: relative;
    padding: 0;
    transition: all $animationSpeed ease-in-out;
    border-bottom: 0;
    width: 100% !important;

    &::after,
    &::before {
        position: absolute;
        width: 10px;
        height: 1px;
        background: $c_black;
        content: '';
        z-index: 5;
        margin-top: 23px;
        margin-right: 10px;
        transition: all $animationSpeed ease-in-out;
    }

    &::before {
        right: 7px;
        transform: rotate(-36deg);

        [dir='rtl'] & {
            right: auto;
            left: 7px;
        }
    }

    &::after {
        top: 0;
        right: 0;
        transform: rotate(36deg);

        [dir='rtl'] & {
            right: auto;
            left: 0;
        }
    }

    .dropdown-toggle {
        position: relative;
        overflow: hidden;
        border: none;
        height: 48px;
        width: 100%;
        padding-right: 25px;
        font-weight: 700;
        border-bottom: 1px solid $c_lightgray50;
        text-align: left;
        color: $c_black;
        background: transparent;
        transition: all $animationSpeed ease-in-out;

        &:hover {
            background: $c_lightgray15;
            border-bottom: 1px solid $c_lightgray25;
            outline: none;
        }

        &::before {
            width: 100%;
            height: 100%;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            transition: all $animationSpeed ease;
            background: transparent;
        }

        > div {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            span {
                color: $c_gray75;
            }
        }

        [dir='rtl'] & {
            text-align: right;
            padding-right: 15px;
            padding-left: 25px;
        }
    }

    .dropdown-menu {
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        max-height: 0;
        overflow: hidden;
        transition: all $animationSpeed ease;
        box-shadow: none;
        border: none;

        @media (max-width: $screen-sm-max) {
            position: static;
        }

        > li {
            cursor: pointer;

            > * {
                padding: 10px 20px;
                white-space: normal;
            }
        }

        .view_more {
            div {
                cursor: pointer;
                color: $c_darkblue;
            }

            i {
                font-size: $fs16;
            }
        }
    }

    &.collapsed {
        &::before {
            transform: rotate(36deg);
        }

        &::after {
            transform: rotate(-36deg);
        }

        .btn {
            box-shadow: none;

            &::before {
                transition: all $animationSpeed ease;
                transform: translate(0, 140px);
            }
        }

        .dropdown-menu {
            transition: all $animationSpeed ease;
            max-height: 1000px;
            border-bottom: 1px solid $c_lightgray25;
        }
    }
}

.dropdown-accordion .panel-title a {
    &::after,
    &::before {
        margin-top: 12px;
    }
}

// checkboxes and radios auf Basis von: https://github.com/flatlogic/awesome-bootstrap-checkbox
.checkbox {
    margin-bottom: 0;

    label {
        position: relative;
        padding-left: 30px;

        [dir='rtl'] & {
            padding-left: 0;
            padding-right: 30px;
        }

        &::before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            left: 0;
            border-bottom: 1px solid #d8d8d8;
            border-radius: 0;
            background-color: $c_lightgray25;
            transition: border $animationSpeed ease-in-out, color 0.15s ease-in-out;
            top: 50%;
            margin-top: -10px;

            [dir='rtl'] & {
                right: 0;
                left: auto;
            }
        }

        &::after {
            font-size: 14px;
            position: absolute;
            width: 20px;
            height: 20px;
            top: 50%;
            left: 0;
            margin-top: -6px;
            padding-left: 3px;
            color: $c_darkblue;

            [dir='rtl'] & {
                right: 0;
                left: auto;
                padding-left: 0;
                padding-right: 3px;
            }
        }

    }

    input[type="checkbox"],
    input[type="radio"] {
        opacity: 0;
        z-index: 1;
        margin: auto 3px;

        &:focus ~ label::before,
        &:focus ~ label::before {
            outline: thin dotted;
            outline: 5px auto -webkit-focus-ring-color;
            outline-offset: -2px;
        }

        &:checked ~ label::after,
        &:checked ~ label::after {
            @include imoon("\e90c");
        }

        &:indeterminate ~ label::after,
        &:indeterminate ~ label::after {
            display: block;
            content: "";
            width: 10px;
            height: 3px;
            background-color: #555555;
            border-radius: 2px;
            margin-left: -16.5px;
            margin-top: 7px;
        }

        &:disabled ~ label,
        &:disabled ~ label {
            opacity: 0.65;
        }

        &:disabled ~ label::before,
        &:disabled ~ label::before {
            background-color: #eeeeee;
            cursor: not-allowed;
        }
    }

    &.checkbox-circle label::before {
        border-radius: 50%;
    }

    &.checkbox-inline {
        margin-top: 0;
    }
}

.radio {
    padding-left: 20px;

    label {
        display: block;
        // vertical-align: middle;
        position: relative;
        padding-left: 5px;

        &::before {
            content: "";
            position: absolute;
            width: 17px;
            height: 17px;
            top: 0;
            left: 0;
            margin-left: -20px;
            border: 1px solid #cccccc;
            border-radius: 50%;
            background-color: #fff;
            transition: border $animationSpeed ease-in-out;
        }

        &::after {
            position: absolute;
            content: " ";
            width: 11px;
            height: 11px;
            left: 3px;
            top: 3px;
            margin-left: -20px;
            border-radius: 50%;
            background-color: #555555;
            transform: scale(0, 0);
            transition: transform $animationSpeed cubic-bezier(0.8, -0.33, 0.2, 1.33);
        }
    }

    input[type="radio"] {
        opacity: 0;
        z-index: 1;

        &:focus + label::before {
            outline: thin dotted;
            outline: 5px auto -webkit-focus-ring-color;
            outline-offset: -2px;
        }

        &:checked + label::after {
            transform: scale(1, 1);
        }

        &:disabled + label {
            opacity: 0.65;
        }

        &:disabled + label::before {
            cursor: not-allowed;
        }
    }

    &.radio-inline {
        margin-top: 0;
    }
}

// opera workaround
input[type="checkbox"].styled:checked + label::after,
input[type="radio"].styled:checked + label::after {
    @include imoon("\e90c");
}

input[type="checkbox"] .styled:checked + label::before,
input[type="radio"] .styled:checked + label::before {
    color: #fff;
}

input[type="checkbox"] .styled:checked + label::after,
input[type="radio"] .styled:checked + label::after {
    color: #fff;
}

fieldset.section {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

.inputfield {
    margin-top: 15px;
    margin-bottom: 8px;

    label {
        display: block;
        font-size: 10px;
        color: $c_black;
        margin: 0 0 3px;
        padding: 0 15px 0;
        font-weight: normal;
        font-weight: 700;

        &.required::after {
            content: '*';
            margin-left: 4px;
        }
    }

    // Default text input
    input[type=text],
    input[type=search],
    input[type=password],
    input[type=email] {
        font-size: $fs14;
        display: block;
        width: 100%;
        border: none;
        background: none;
        padding: 0 15px;
        height: 48px;
        border-bottom: 1px solid $c_lightgray75;

        &::placeholder {
            color: $c_lightgray;
        }

        &:not([readonly]):hover,
        &:not([readonly]):focus {
            background-color: $c_lightgray25;
        }
    }

    // selectbox
    .bootstrap-select {
        .filter-option {
            font-size: $fs14;
        }

        > .dropdown-toggle.bs-placeholder {
            &:focus,
            &:active {
                color: $c_lightgray;
            }

            &:hover {
                color: $c_black;
            }
        }
    }

    textarea {
        display: block;
        width: 100%;
        padding: 15px;
        border: none;
        border-bottom: 1px solid $c_lightgray75;

        &:hover,
        &:focus {
            background-color: $c_lightgray25;
        }
    }

    &.checkbox {
        label {
            padding-left: 30px;
        }
    }

    .radio {
        padding-left: 35px;
    }

    .success {
        border-color: $c_lightgreen;
    }

    .warning {
        border-color: $c_lightorange;
    }

    .error {
        border-color: $c_red;
    }

    .inactive {
        color: rgba($c_lightgray50, .5);
        border-color: $c_lightgray25;
    }

    &--search {
        position: relative;

        &__reset {
            position: absolute;
            top: 12px;
            right: 5px;
            font-size: 22px;
            color: $c_lightgray75;
            cursor: pointer;

            &::before {
                @include imoon("\e911");
            }
        }

        input[type=search]:focus {
            padding-right: 30px;
        }
    }
}

.checkbox {
    label {
        font-weight: 400;
        font-size: inherit;

        &::after,
        &::before {
            top: 9px;
        }
    }
}

.label {
    &--right {
        width: 100%;

        @media (min-width: $screen-sm-min) {
            width: 50%;
            text-align: right;
            padding-right: 1em;
        }
    }
}
