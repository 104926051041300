@import 'config/init';

// Molecule Breadcrumb
.m-breadcrumb {
    &__items {
        background-color: transparent;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        list-style: none;
        height: 56px;
    }

    &__item {
        display: none;
        font-size: 12px;

        &:only-child {
            display: inline;
            font-size: 12px;
        }

        @media (min-width: $screen-sm-min) {
            display: inline;
        }

        &--separator {
            display: none;
        }

        &--hide {
            display: none;

            @media (min-width: $screen-sm-min) {
                display: none;
            }
        }

        &--active {
            display: inline;
            font-size: 12px;

            a {
                color: $c_navyblue15;
                font-size: 12px;

                &::after {
                    @media (max-width: $screen-xs-max) {
                        font-family: $f_icons;
                        content: '\e907';
                        color: $c_navyblue15;
                        padding: 0 10px;
                    }
                }
            }
        }

        &:nth-last-child(2) {
            display: inline;
        }
    }

    &--active {
        display: inline;
    }

    &__link,
    &__current {
        display: flex;
        align-items: center;
        color: $c_navyblue15;

        &::after {
            @include imoon('\e905');

            padding: 0 10px;

            [dir='rtl'] & {
                content: none;
            }
        }

        &::before {
            content: none;

            [dir='rtl'] & {
                @include imoon('\e908');

                padding: 0 10px;
                float: left;
            }
        }

        &:hover,
        &:focus,
        &:active {
            color: $c_navyblue15;
        }

        .m-breadcrumb__item:first-of-type & {
            &::after {
                [dir='rtl'] & {
                    content: none !important;
                }
            }

            &::before {
                [dir='rtl'] & {
                    content: none;
                }
            }
        }

        .m-breadcrumb__item:last-of-type & {
            &::after {
                content: none !important;
            }
        }
    }

    &__current {
        .set-first--cutted {
            &::after {
                content: '…';
            }
        }
    }
}

.o-header--alt {
    .m-breadcrumb {
        &__link {
            color: $c_lightgray;
        }

        &__current {
            color: $c_black;
        }
    }
}
