@import 'config/init';

// Atom Icons - ONLY WOFF FONT !!!
@font-face {
    font-family: $f_icons;
    font-display: swap;
    src: url('../fonts/fonticons/#{$f_icons}.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

[class^='icon-'],
[class^='path'],
.slick-next::before,
.slick-prev::before {
    font-family: $f_icons !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 29px;
}

.icon-wrench::before {
    content: '\e9c6';
}

.icon-worldwideweb::before {
    content: '\e9c3';
}

.icon-confirmation::before {
    content: '\e961';
}

.icon-trophy::before {
    content: '\e9c0';
}

.icon-jewel::before {
    content: '\e9c1';
}

.icon-hammer::before {
    content: '\e9c2';
}

.icon-Bosch-Ic-survey::before {
    content: '\e9bf';
}

.icon-pinch-to-zoom::before {
    content: '\e9be';
}

.icon-drill-driver-cordless::before {
    content: '\e9bd';
}

.icon-arrows-expand::before {
    content: '\e9bc';
}

.icon-Bosch-icon-measuring-blue::before {
    content: '\e9bb';
}

.icon-alert-error-filled::before {
    content: '\e9b3';
}

.icon-alert-error::before {
    content: '\e9b4';
}

.icon-alert-info-filled::before {
    content: '\e9b5';
}

.icon-alert-info::before {
    content: '\e9b6';
}

.icon-alert-success-filled::before {
    content: '\e9b7';
}

.icon-alert-success::before {
    content: '\e9b8';
}

.icon-alert-warning-filled::before {
    content: '\e9b9';
}

.icon-alert-warning::before {
    content: '\e9ba';
}

.icon-delete::before {
    content: '\e9b2';
}

.icon-barcode-scanner::before {
    content: '\e9ae';
}

.icon-checkmark::before {
    content: '\e9af';
}

.icon-customerservice::before {
    content: '\e9b0';
}

.icon-desktop-notification::before {
    content: '\e9b1';
}

.icon-route::before {
    content: '\e9ab';
}

.icon-newsletter::before {
    content: '\e9ac';
}

.icon-externallink::before {
    content: '\e9ad';
}

.icon-badge-checkmark::before {
    content: '\e9a6';
}

.icon-box-delivery::before {
    content: '\e9a7';
}

.icon-cash-frame::before {
    content: '\e9a8';
}

.icon-configuration::before {
    content: '\e9a9';
}

.icon-customer::before {
    content: '\e9aa';
}

.icon-reset::before {
    content: '\e9a1';
}

.icon-filter-add::before {
    content: '\e9a2';
}

.icon-filter-delete::before {
    content: '\e9a3';
}

.icon-filter-success::before {
    content: '\e9a4';
}

.icon-filter::before {
    content: '\e9a5';
}

.icon-Bosch-Ic-store-locator-01-filled::before {
    content: '\e9a0';
}

.icon-Bosch-Ic-link::before {
    content: '\e99f';
}

.icon-smartphone::before {
    content: '\e99e';
}

.icon-info-i-circle::before {
    content: '\e99d';
}

.icon-Bosch-Ic-sm-youku1::before {
    content: '\e99b';
}

.icon-Bosch-Ic-sm-youku2::before {
    content: '\e99c';
}

.icon-Bosch-Ic-percent-label::before {
    content: '\e99a';
}

.icon-Bosch-Ic-sm-naver::before {
    content: '\e999';
}

.icon-Bosch-Ic-question-frame::before {
    content: '\e998';
}

.icon-Bosch-Ic-call::before {
    content: '\e996';
}

.icon-Bosch-Ic-mail::before {
    content: '\e997';
}

.icon-Bosch-Ic-sm-instagram::before {
    content: '\e995';
}

.icon-Bosch-IC-maximize::before {
    content: '\e994';
}

.icon-Bosch-Ic-sm-douban::before {
    content: '\e978';
}

.icon-Bosch-Ic-sm-facebook::before {
    content: '\e979';
}

.icon-Bosch-Ic-sm-googleplus::before {
    content: '\e97a';
}

.icon-Bosch-Ic-sm-iwiw::before {
    content: '\e97b';
}

.icon-Bosch-Ic-sm-kaixin::before {
    content: '\e97c';
}

.icon-Bosch-Ic-sm-line::before {
    content: '\e97d';
}

.icon-Bosch-Ic-sm-linked-in::before {
    content: '\e97e';
}

.icon-Bosch-Ic-sm-mail::before {
    content: '\e97f';
}

.icon-Bosch-Ic-sm-mxit::before {
    content: '\e980';
}

.icon-Bosch-Ic-sm-myspace::before {
    content: '\e981';
}

.icon-Bosch-Ic-sm-odnoklassniki::before {
    content: '\e982';
}

.icon-Bosch-Ic-sm-orkut::before {
    content: '\e983';
}

.icon-Bosch-Ic-sm-pixnet::before {
    content: '\e984';
}

.icon-Bosch-Ic-sm-plurk::before {
    content: '\e985';
}

.icon-Bosch-Ic-sm-pokec::before {
    content: '\e986';
}

.icon-Bosch-Ic-sm-qzone::before {
    content: '\e987';
}

.icon-Bosch-Ic-sm-renren::before {
    content: '\e988';
}

.icon-Bosch-Ic-sm-sharethis::before {
    content: '\e989';
}

.icon-Bosch-Ic-sm-sina-weibo::before {
    content: '\e98a';
}

.icon-Bosch-Ic-sm-taringa::before {
    content: '\e98b';
}

.icon-Bosch-Ic-sm-telegram::before {
    content: '\e98c';
}

.icon-Bosch-Ic-sm-tumblr::before {
    content: '\e98d';
}

.icon-Bosch-Ic-sm-twitter::before {
    content: '\e98e';
}

.icon-Bosch-Ic-sm-vkontakte::before {
    content: '\e98f';
}

.icon-Bosch-Ic-sm-wechat::before {
    content: '\e990';
}

.icon-Bosch-Ic-sm-tiktok::before {
    content: '\e9c4';
}

.icon-Bosch-Ic-sm-whatsapp::before {
    content: '\e991';
}

.icon-Bosch-Ic-sm-yahoo::before {
    content: '\e992';
}

.icon-Bosch-Ic-sm-zing-me::before {
    content: '\e993';
}

.icon-Bosch-Ic-outline-email::before {
    content: '\e975';
}

.icon-Bosch-Ic-outline-sms::before {
    content: '\e976';
}

.icon-Bosch-Ic-outline-whatsapp::before {
    content: '\e977';
}

.icon-Bosch-Ic-useractivity::before {
    content: '\e973';
}

.icon-Bosch-Ic-useractivity-filled::before {
    content: '\e974';
}

.icon-Bosch-Ic-heart::before {
    content: '\e96f';
}

.icon-Bosch-Ic-heart-active::before {
    content: '\e970';
}

.icon-Bosch-Ic-share::before {
    content: '\e971';
}

.icon-Bosch-Ic-share-active::before {
    content: '\e972';
}

.icon-Bosch-Ic-double-arrow-link-left::before {
    content: '\e96d';
}

.icon-Bosch-Ic-double-arrow-link-right::before {
    content: '\e96e';
}

.icon-Bosch-Ic-accessories::before {
    content: '\e926';
}

.icon-construction::before {
    content: '\e91f';
}

.icon-electrician::before {
    content: '\e920';
}

.icon-industry::before {
    content: '\e921';
}

.icon-joiners-and-carpenters::before {
    content: '\e922';
}

.icon-landscaping::before {
    content: '\e923';
}

.icon-metal-construction::before {
    content: '\e924';
}

.icon-plumbing-heating-and-air-conditioning-technology::before {
    content: '\e925';
}

.icon-Bosch-Ic-AD::before {
    content: '\e918';
}

.icon-Bosch-Ic-delivery::before {
    content: '\e919';
}

.icon-Bosch-Ic-desktop::before {
    content: '\e91a';
}

.icon-bosch-ic-my-product::before {
    content: '\e91b';
}

.icon-Bosch-Ic-payment::before {
    content: '\e91c';
}

.icon-bosch-ic-promotion-bundle::before {
    content: '\e91d';
}

.icon-bosch-ic-registration::before {
    content: '\e91e';
}

.icon-Bosch-Ic-store-locator-01-black::before {
    content: '\e917';
}

.icon-Bosch-Ic-compare::before {
    content: '\e90d';
}

.icon-Bosch-Ic-checkmark::before {
    content: '\e90c';
}

.icon-Bosch-Ic-battery::before {
    content: '\e96c';
}

.icon-Bosch-Ic-externallink::before {
    content: '\e96b';
}

.icon-Bosch-Ic-info::before {
    content: '\e969';
}

.icon-Bosch-Ic-reset-selection::before {
    content: '\e96a';
}

.icon-Bosch-Ic-download-2::before {
    content: '\e964';
}

.icon-Bosch-Ic-geo-localization::before {
    content: '\e965';
}

.icon-Bosch-Ic-grid-view::before {
    content: '\e966';
}

.icon-Bosch-Ic-list-view::before {
    content: '\e967';
}

.icon-Bosch-Ic-spareparts2::before {
    content: '\e968';
}

.icon-Bosch-Ic-measuring-tools::before {
    content: '\e962';
}

.icon-Bosch-Ic-power-tools::before {
    content: '\e963';
}

.icon-Bosch-Ic-clock::before {
    content: '\e960';
}

.icon-Bosch-Ic-facebook::before {
    content: '\e95d';
}

.icon-Bosch-Ic-twitter::before {
    content: '\e95e';
}

.icon-Bosch-Ic-youtube::before {
    content: '\e95f';
}

.icon-Bosch-Ic-360-view::before {
    content: '\e900';
}

.icon-Bosch-Ic-add-to-cart-black::before {
    content: '\e901';
}

.icon-Bosch-Ic-apps::before {
    content: '\e902';
}

.icon-Bosch-Ic-arrow-black-bottom::before {
    content: '\e903';
}

.icon-Bosch-Ic-arrow-black-left::before {
    content: '\e904';
}

.icon-Bosch-Ic-arrow-black-right::before {
    content: '\e905';
}

.icon-Bosch-Ic-arrow-black-top::before {
    content: '\e906';
}

.icon-Bosch-Ic-arrow-link-bottom::before {
    content: '\e907';
}

.icon-Bosch-Ic-arrow-link-left::before {
    content: '\e908';
}

.icon-Bosch-Ic-arrow-link-right::before {
    content: '\e909';
}

.icon-Bosch-Ic-arrow-link-top::before {
    content: '\e90a';
}

.icon-Bosch-Ic-arrow-thin-bottom::before {
    content: '\e90b';
}

.icon-Bosch-Ic-arrow-thin-left::before {
    content: '\e90e';
}

.icon-Bosch-Ic-arrow-thin-right::before {
    content: '\e90f';
}

.icon-Bosch-Ic-arrow-thin-top::before {
    content: '\e910';
}

.icon-Bosch-Ic-close-black::before {
    content: '\e911';
}

.icon-Bosch-Ic-comfort::before {
    content: '\e912';
}

.icon-Bosch-Ic-customer-service-black::before {
    content: '\e913';
}

.icon-Bosch-Ic-download-black::before {
    content: '\e914';
}

.icon-Bosch-Ic-edit::before {
    content: '\e915';
}

.icon-Bosch-Ic-forum::before {
    content: '\e916';
}

.icon-Bosch-Ic-less-minimize-black::before {
    content: '\e927';
}

.icon-Bosch-Ic-logout::before {
    content: '\e928';
}

.icon-Bosch-Ic-menu-black::before {
    content: '\e929';
}

.icon-Bosch-Ic-more-maximize-black::before {
    content: '\e92a';
}

.icon-Bosch-Ic-mybrand-black::before {
    content: '\e92b';
}

.icon-Bosch-Ic-newsletter-black::before {
    content: '\e92c';
}

.icon-Bosch-Ic-outline-chat::before {
    content: '\e92d';
}

.icon-Bosch-Ic-outline-facebook::before {
    content: '\e92e';
}

.icon-Bosch-Ic-outline-nl::before {
    content: '\e92f';
}

.icon-Bosch-Ic-outline-print::before {
    content: '\e930';
}

.icon-Bosch-Ic-outline-share::before {
    content: '\e931';
}

.icon-Bosch-Ic-outline-twitter::before {
    content: '\e932';
}

.icon-Bosch-Ic-outline-youtube::before {
    content: '\e933';
}

.icon-Bosch-Ic-print-black::before {
    content: '\e934';
}

.icon-Bosch-Ic-privacysetting-comfort-black::before {
    content: '\e935';
}

.icon-Bosch-Ic-privacysetting-neccessary-black::before {
    content: '\e936';
}

.icon-Bosch-Ic-privacysetting-statisitcs::before {
    content: '\e937';
}

.icon-Bosch-Ic-privacysetting-targeting::before {
    content: '\e938';
}

.icon-Bosch-Ic-quotemarke-up::before {
    content: '\e939';
}

.icon-Bosch-Ic-quotemarks-down::before {
    content: '\e93a';
}

.icon-Bosch-Ic-search-black::before {
    content: '\e93b';
}

.icon-Bosch-Ic-select-all::before {
    content: '\e93c';
}

.icon-Bosch-Ic-shoppingcart-black::before {
    content: '\e93d';
}

.icon-Bosch-Ic-spareparts::before {
    content: '\e93e';
}

.icon-Bosch-Ic-start_video_play::before {
    content: '\e93f';
}

.icon-Bosch-Ic-store-locator-black::before {
    content: '\e940';
}

.icon-Bosch-Ic-touch::before {
    content: '\e941';
}

.icon-Bosch-Ic-up-black::before {
    content: '\e942';
}

.icon-Bosch-Ic-usermanual::before {
    content: '\e943';
}

.icon-Bosch-Ic-video-fullscreen::before {
    content: '\e944';
}

.icon-Bosch-Ic-video-pause::before {
    content: '\e945';
}

.icon-Bosch-Ic-video-play::before {
    content: '\e946';
}

.icon-Bosch-Ic-video-volume-1 .path1::before {
    content: '\e947';
    color: rgb(0, 86, 145);
}

.icon-Bosch-Ic-video-volume-1 .path2::before {
    content: '\e948';
    margin-left: -1em;
    color: rgb(191, 192, 194);
}

.icon-Bosch-Ic-video-volume-1 .path3::before {
    content: '\e949';
    margin-left: -1em;
    color: rgb(191, 192, 194);
}

.icon-Bosch-Ic-video-volume-1 .path4::before {
    content: '\e94a';
    margin-left: -1em;
    color: rgb(191, 192, 194);
}

.icon-Bosch-Ic-video-volume-1 .path5::before {
    content: '\e94b';
    margin-left: -1em;
    color: rgb(191, 192, 194);
}

.icon-Bosch-Ic-video-volume-2 .path1::before {
    content: '\e94c';
    color: rgb(0, 86, 145);
}

.icon-Bosch-Ic-video-volume-2 .path2::before {
    content: '\e94d';
    margin-left: -1em;
    color: rgb(0, 86, 145);
}

.icon-Bosch-Ic-video-volume-2 .path3::before {
    content: '\e94e';
    margin-left: -1em;
    color: rgb(191, 192, 194);
}

.icon-Bosch-Ic-video-volume-2 .path4::before {
    content: '\e94f';
    margin-left: -1em;
    color: rgb(191, 192, 194);
}

.icon-Bosch-Ic-video-volume-2 .path5::before {
    content: '\e950';
    margin-left: -1em;
    color: rgb(191, 192, 194);
}

.icon-Bosch-Ic-video-volume-3 .path1::before {
    content: '\e951';
    color: rgb(0, 86, 145);
}

.icon-Bosch-Ic-video-volume-3 .path2::before {
    content: '\e952';
    margin-left: -1em;
    color: rgb(0, 86, 145);
}

.icon-Bosch-Ic-video-volume-3 .path3::before {
    content: '\e953';
    margin-left: -1em;
    color: rgb(0, 86, 145);
}

.icon-Bosch-Ic-video-volume-3 .path4::before {
    content: '\e954';
    margin-left: -1em;
    color: rgb(191, 192, 194);
}

.icon-Bosch-Ic-video-volume-3 .path5::before {
    content: '\e955';
    margin-left: -1em;
    color: rgb(191, 192, 194);
}

.icon-Bosch-Ic-video-volume-4 .path1::before {
    content: '\e956';
    color: rgb(0, 86, 145);
}

.icon-Bosch-Ic-video-volume-4 .path2::before {
    content: '\e957';
    margin-left: -1em;
    color: rgb(0, 86, 145);
}

.icon-Bosch-Ic-video-volume-4 .path3::before {
    content: '\e958';
    margin-left: -1em;
    color: rgb(0, 86, 145);
}

.icon-Bosch-Ic-video-volume-4 .path4::before {
    content: '\e959';
    margin-left: -1em;
    color: rgb(0, 86, 145);
}

.icon-Bosch-Ic-video-volume-4 .path5::before {
    content: '\e95a';
    margin-left: -1em;
    color: rgb(191, 192, 194);
}

.icon-Bosch-Ic-video-volume-5::before {
    content: '\e95b';
}

.icon-Bosch-Ic-wishlist-black::before {
    content: '\e95c';
}

.icon-van-delivery::before {
    content: '\e9c5';
}
