@import "config/init";

// Atom Headlines
.headline,
.subline {
    font-weight: 200;
    letter-spacing: -0.02em;
}

.headline {
    // font-weight: 500;
    line-height: 1.1;
    margin-top: 20px;
    margin-bottom: 10px;
}

.hl1 {
    font-size: 32px;
    line-height: 36px;

    @media (min-width: $screen-sm-min) {
        font-size: 36px;
        line-height: 40px;
    }

    @media (min-width: $screen-md-min) {
        font-size: 48px;
        line-height: 52px;
    }
}

.hl2 {
    font-size: 28px;
    line-height: 1.1;

    @media (min-width: $screen-md-min) {
        font-size: 36px;
        line-height: 1.1;
    }
}

.hl3 {
    font-size: 24px;
    line-height: 28px;

    @media (min-width: $screen-md-min) {
        font-size: 28px;
        line-height: 32px;
    }
}

.hl4 {
    font-size: 20px;
    line-height: 1.1;

    @media (min-width: $screen-md-min) {
        font-size: 24px;
        line-height: 1.1;
    }
}

.hl5 {
    font-size: 16px;
    line-height: 1.1;

    @media (min-width: $screen-sm-min) {
        font-size: 20px;
        line-height: 1.1;
    }
}

.hl6 {
    font-size: 16px;
    line-height: 1.1;

    @media (min-width: $screen-sm-min) {
        font-size: 16px;
        line-height: 1.1;
    }
}

.large {
    font-weight: 200;
    font-size: 32px;
    line-height: 42px;

    @media (min-width: $screen-sm-min) {
        font-size: 48px;
        line-height: 51px;
    }

    @media (min-width: $screen-md-min) {
        font-size: 60px;
        line-height: 64px;
    }
}

.larger {
    font-weight: 600;
    font-size: 100px;
    line-height: 96px;
}

.largest {
    font-weight: 600;
    font-size: 144px;
    line-height: 144px;
}

.small {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.headline--redesign {
    letter-spacing: -.065em;
    margin: 10px 0;
    text-transform: uppercase;
    font-weight: 900;
}

.headline__redesign {
    margin: 10px 0;
    font-size: 32px;
    line-height: 42px;
    text-transform: uppercase;
    font-weight: 900;

    @media (min-width: $screen-xs-min) {
        font-size: 40px;
        line-height: 40px;
    }

    @media (min-width: $screen-sm-min) {
        font-size: 48px;
        line-height: 48px;
    }

    @media (min-width: $screen-md-min) {
        font-size: 60px;
        line-height: 60px;
    }

    &--blue {
        color: $c_problue !important;
    }

    &--white {
        color: $c_white !important;
    }

    &--black {
        color: $c_black !important;
    }

    &--hl1 {
        font-weight: 900;
        font-size: 32px !important;
        line-height: 40px !important;
        letter-spacing: -0.02em;

        @media (min-width: $screen-md-min) {
            font-size: 48px !important;
            line-height: 48px !important;
            letter-spacing: -0.05em;
        }
    }

    &--hl2 {
        font-weight: 900;
        font-size: 24px !important;
        line-height: 32px !important;
        letter-spacing: -0.02em;

        @media (min-width: $screen-md-min) {
            font-size: 36px !important;
            line-height: 42px !important;
        }
    }

    &--hl3 {
        font-weight: 700;
        font-size: 27px !important;
        line-height: 32px !important;
        letter-spacing: -0.02em;

        @media (min-width: $screen-md-min) {
            font-size: 28px !important;
            line-height: 34px !important;
        }
    }

    &--hl4 {
        font-weight: 700;
        font-size: 20px !important;
        line-height: 24px !important;
        letter-spacing: 0;

        @media (min-width: $screen-md-min) {
            font-size: 24px !important;
            line-height: 28px !important;
            letter-spacing: -0.02em;
        }
    }

    &--hl5 {
        font-weight: 700;
        font-size: 18px !important;
        line-height: 22px !important;
        letter-spacing: 0;

        @media (min-width: $screen-sm-min) {
            font-size: 20px !important;
            line-height: 24px !important;
        }
    }

    &--hl6 {
        font-weight: 700;
        font-size: 16px !important;
        line-height: 22px !important;

        @media (min-width: $screen-sm-min) {
            font-size: 14px !important;
            line-height: 20px !important;
        }

        @media (min-width: $screen-md-min) {
            font-size: 20px !important;
            line-height: 24px !important;
        }
    }
}
