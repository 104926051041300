.a-availability {
    &::before {
        content: '●';
        margin-right: 3px;
    }

    &--full {
        color: $c_lightgreen;
    }

    &--low {
        color: $c_lightorange;

        .dot {
            background-color: $c_lightorange;
        }
    }

    &--outofstock {
        color: $c_red;

        .dot {
            background-color: $c_red;
        }
    }

    &--availableonrequest {
        color: $c_lightorange;

        .dot {
            background-color: $c_lightorange;
        }
    }

    &--supplierdelivery {
        color: $c_black;
    }

    &--onrequest {
        color: $c_gray;
    }
}
