// Global CSS Classes used on every page
@import 'config/init';
@import 'a-availability';
@import 'a-buttons';
@import 'a-bba-buttons';
@import 'a-flags';
@import 'a-forms';
@import 'a-headlines';
@import 'a-icons';
@import 'a-lists';
@import 'a-text';
@import 'a-bba-text';
@import 'a-price';
@import 'a-ordernumber';
@import 'a-readline';
@import 'a-ocs-flags';
@import 'm-main_navigation';
@import 'm-searchNavigation';
@import 'm-header_navigation_flyout';
@import 'm-breadcrumb';
@import 'm-conversion_bar';
@import 'm-metanavigation';
@import 'm-table';
@import 'm-comp_tooltip';
@import 'm-shopping-cart';
@import 'm-user-actions';
@import 'o-header';
@import 'o-footer';

html {
    font-size: 16px;
}

.full-width-element {
    width: 100%;
}

.white-bg {
    background-color: $c_white;
}

.bosch-grey-bg {
    background: $c_boschgray;
}

// section
section {
    padding-top: 30px;
    padding-bottom: 30px;

    @media (min-width: $screen-md-min) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.section {
    // No padding-bottom for Tablet (used in grid-slider)
    &--sm-pb-0 {
        padding-bottom: 0;
    }

    &--p-0 {
        padding: 0;
    }
}

// container
.container {
    width: 100%;

    @media (min-width: $screen-md-min) {
        max-width: 1170px;
    }
}

.container-fluid {
    width: 100%;
}

// grid-gutter update 20170117
@media (max-width: $screen-xs-max) {
    .container-fluid {
        padding-left: 5px;
        padding-right: 5px;

        .row {
            margin-left: -5px;
            margin-right: -5px;
        }

        [class*=col-xs],
        [class*=col-sm],
        [class*=col-md],
        [class*=col-lg] {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
}

// Outlines ausblenden
*:focus,
a:focus {
    outline: 0;
    outline-offset: 0;
}

// img-responsive full-width
.img-full-width {
    width: 100%;
}

.lazyload.lqip {
    width: 100% !important;
    max-width: none !important;
    filter: blur(5px);
    transition: filter 100ms;

    &.lazyloaded {
        filter: blur(0);
    }
}

/*******************************************************************
  Bilder mit data-sizes="auto" immer width:100% damit das richtige Bild geladen wird
  https://github.com/aFarkas/lazysizes
*/
img[data-sizes=auto] {
    display: block;
    width: 100%;
}

// flex helper class
.flex {
    display: flex;

    // Remove bootstrap clearfix because of Safari Bug
    &.row::after,
    &.row::before {
        display: none;
    }

    .flex-wrap,
    &.flex-wrap {
        flex-wrap: wrap;
    }

    .flex-nowrap,
    &.flex-nowrap {
        flex-wrap: nowrap;
    }

    .flex-direction,
    &.flex-direction {
        &-row {
            flex-direction: row;
        }

        &-column {
            flex-direction: column;
        }
    }

    .align-items,
    &.align-items {
        &-start {
            align-items: flex-start;
        }

        &-center {
            align-items: center;
        }

        &-end {
            align-items: flex-end;
        }
    }

    .justify-content,
    &.justify-content {
        &-start {
            justify-content: flex-start;
        }

        &-center {
            justify-content: center;
        }

        &-end {
            justify-content: flex-end;
        }

        &-space-between {
            justify-content: space-between;
        }
    }

}

// row with equal cols
.row.is-flex {
    display: flex;
    flex-wrap: wrap;
}

.row.is-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

// popover
.popover {
    border-radius: 0;
    z-index: $zindex-popover;

    .popover-title {
        background-color: transparent;
        border: none;
        border-radius: 0;
        padding: 0;

        .share_options & {
            display: none;
        }
    }

    .popover-content {
        padding: 30px;

        .bba-btn {
            margin-top: 16px;
        }
    }

    .popover__content-close {
        position: absolute;
        background: transparent;
        padding: 0 0 8px 5px;
        top: 0;
        right: 0;
        line-height: normal;

        [dir='rtl'] & {
            right: auto;
            left: 0;
        }

        i {
            font-size: 16px;
        }
    }

    &--dealer {
        max-width: 340px;
        border: solid 1px $c_lightgray50;

        .popover-content {
            padding: 0 20px 20px;

            ul {
                padding-left: 20px;
            }
        }

        .popover-close {
            background: transparent;
            border: 0;
            padding: 5px;
            cursor: pointer;
        }
    }
}

/*  YT Responsive Videos */
.yt-video {
    position: relative;
    padding-top: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &--16-9 {
        @include aspect-ratio(16, 9);
    }

    &--4-3 {
        @include aspect-ratio(4, 3);
    }

    &--3-2 {
        @include aspect-ratio(3, 2);
    }
}

.aspect-ratio--16-9 {
    @include aspect-ratio(16,9);
}

/*  Bootstrap Clearfix (http://www.bluthemes.com/blog/3/clearing-bootstrap-3-columns) */

/*  Tablet  */
@media (min-width: $screen-sm-min) {
    /* Column clear fix */
    .col-lg-1:nth-child(12n+1),
    .col-lg-2:nth-child(6n+1),
    .col-lg-3:nth-child(4n+1),
    .col-lg-4:nth-child(3n+1),
    .col-lg-6:nth-child(2n+1),
    .col-md-1:nth-child(12n+1),
    .col-md-2:nth-child(6n+1),
    .col-md-3:nth-child(4n+1),
    .col-md-4:nth-child(3n+1),
    .col-md-6:nth-child(2n+1) {
        clear: none;
    }

    .col-sm-1:nth-child(12n+1),
    .col-sm-2:nth-child(6n+1),
    .col-sm-3:nth-child(4n+1),
    .col-sm-4:nth-child(3n+1),
    .col-sm-6:nth-child(2n+1) {
        clear: left;
    }
}

/*  Medium Desktop  */
@media (min-width: $screen-md-min) {
    /* Column clear fix */
    .col-lg-1:nth-child(12n+1),
    .col-lg-2:nth-child(6n+1),
    .col-lg-3:nth-child(4n+1),
    .col-lg-4:nth-child(3n+1),
    .col-lg-6:nth-child(2n+1),
    .col-sm-1:nth-child(12n+1),
    .col-sm-2:nth-child(6n+1),
    .col-sm-3:nth-child(4n+1),
    .col-sm-4:nth-child(3n+1),
    .col-sm-6:nth-child(2n+1) {
        clear: none;
    }

    .col-md-1:nth-child(12n+1),
    .col-md-2:nth-child(6n+1),
    .col-md-3:nth-child(4n+1),
    .col-md-4:nth-child(3n+1),
    .col-md-6:nth-child(2n+1) {
        clear: left;
    }
}

/*  Large Desktop  */
@media (min-width: $screen-lg-min) {
    /* Column clear fix */
    .col-md-1:nth-child(12n+1),
    .col-md-2:nth-child(6n+1),
    .col-md-3:nth-child(4n+1),
    .col-md-4:nth-child(3n+1),
    .col-md-6:nth-child(2n+1),
    .col-sm-1:nth-child(12n+1),
    .col-sm-2:nth-child(6n+1),
    .col-sm-3:nth-child(4n+1),
    .col-sm-4:nth-child(3n+1),
    .col-sm-6:nth-child(2n+1) {
        clear: none;
    }

    .col-lg-1:nth-child(12n+1),
    .col-lg-2:nth-child(6n+1),
    .col-lg-3:nth-child(4n+1),
    .col-lg-4:nth-child(3n+1),
    .col-lg-6:nth-child(2n+1) {
        clear: left;
    }
}

// START 9/12 grid (nested grid workaround)
.col-md-9 {
    $colnum: 9;
    $scalefactor: percentage((1/12) * (12/9));

    @media (min-width: $screen-md-min) {
        @for $i from 1 through $colnum {
            .col-md-#{$i} {
                width: $scalefactor * $i;
            }
        }
    }

    @media (min-width: $screen-lg-min) {
        @for $i from 1 through $colnum {
            .col-lg-#{$i} {
                width: $scalefactor * $i;
            }
        }
    }
}
// END 9/12 grid

.line-through {
    text-decoration: line-through;
}

// START Umfrage Layer (kann gelöscht werden sobald umfrage offline)
#skoposSurvey {
    @media (max-width: $screen-xs-max) {
        position: absolute;
    }
}

#skoposSurveyContent {
    max-width: 600px;

    @media (max-width: $screen-xs-max) {
        width: 100%;
        margin: 0 auto !important;
    }

    > p:first-of-type {
        line-height: 30px;
    }
}
// END Umfrage Layer (kann gelöscht werden sobald umfrage offline)

.privacy_prompt_content {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

[dir='rtl'] .media-left {
    padding-left: 10px;
    padding-right: 0;
}

[dir='rtl'] .media-right {
    padding-right: 10px;
    padding-left: 0;
}

.direction-ltr {
    @include setDirection(ltr);
}

[dir='rtl'] .text-left {
    text-align: right !important;
}

[dir='rtl'] .text-right {
    text-align: left !important;
}

[dir='rtl'] .video-player {
    direction: ltr;
}

/* Theme link color */
.theme-blue {
    .link {
        color: $c_white;
        text-decoration: none;

        &:focus,
        &:hover {
            color: $c_darkblue;
            text-decoration: none;
        }
    }
}
