@import 'config/init';

/* Numeric font-weight values

font-weight: 200; // light
font-weight: 400; // normal, regular
font-weight: 600; // medium
font-weight: 700; // bold
font-weight: 900; // black

*/

@font-face {
    font-family: "boschsans";
    font-display: swap;
    src: url("../fonts/boschsans/BoschSans-Black.woff2") format("woff2"), url("../fonts/boschsans/BoschSans-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "boschsans";
    font-display: swap;
    src: url("../fonts/boschsans/BoschSans-Bold.woff2") format("woff2"), url("../fonts/boschsans/BoschSans-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "boschsans";
    font-display: swap;
    src: url("../fonts/boschsans/BoschSans-Medium.woff2") format("woff2"), url("../fonts/boschsans/BoschSans-Medium.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "boschsans";
    font-display: swap;
    src: url("../fonts/boschsans/BoschSans-Regular.woff2") format("woff2"), url("../fonts/boschsans/BoschSans-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "boschsans";
    font-display: swap;
    src: url("../fonts/boschsans/BoschSans-Light.woff2") format("woff2"), url("../fonts/boschsans/BoschSans-Light.woff") format("woff");
    font-weight: 200;
    font-style: normal;
}

.bsLight {
    font-weight: 200;
}

.bsBold {
    font-weight: 700;
}

.bsUnderline {
    text-decoration: underline !important;
}

.bsItalic {
    font-style: italic;
}
// helper font-sizes
.fs12 {
    font-size: $fs12 !important;
}

.fs14 {
    font-size: $fs14 !important;
}

.fs16 {
    font-size: $fs16 !important;
}

.fs18 {
    font-size: $fs18 !important;
}

.fs20 {
    font-size: $fs20 !important;
}

.fs22 {
    font-size: $fs22 !important;
}

.fs24 {
    font-size: $fs24 !important;
}

.fs26 {
    font-size: $fs26 !important;
}

.fs28 {
    font-size: $fs28 !important;
}

.fs32 {
    font-size: $fs32 !important;
}

.fs36 {
    font-size: $fs36 !important;
}

.fs38 {
    font-size: $fs38 !important;
}

.fs48 {
    font-size: $fs48 !important;
}
// links
a {
    font-weight: 400;
    color: $c_black;

    &:focus,
    &:hover {
        color: $c_black;
        text-decoration: none;
    }
}

.link-inside {
    color: $c_darkblue;
    text-decoration: none;

    &:focus,
    &:hover {
        color: $c_darkblue;
        text-decoration: none;
    }
}

.link {
    position: relative;
    color: $c_darkblue;
    font-size: 18px;
    display: inline-block;
    vertical-align: middle;

    p > & {
        vertical-align: baseline;
    }

    &.small {
        font-size: 14px;
    }

    &::after {
        @include imoon('\e905');

        font-size: 80%;
        font-weight: bold;
        vertical-align: middle;
        padding-left: 5px;
        transition: padding-left 0.4s ease-in-out;
        //fixme
        display: inline-block;
        width: 1px;

        [dir='rtl'] & {
            @include imoon('\e908');

            padding-left: 0;
            padding-right: 5px;
            transition: padding-right 0.4s ease-in-out;
        }
    }

    &:focus {
        color: $c_darkblue;
    }

    &:hover {
        color: $c_black;
        text-decoration: none;

        &::after {
            padding-left: 15px;

            [dir='rtl'] & {
                padding-left: 0;
                padding-right: 15px;
            }
        }
    }

    &.hidden_arrow {
        &:hover {
            &::after {
                padding-left: 0;
            }
        }

        &::after {
            content: none;
            padding: 0;
        }
    }
}

.backlink {
    &::before {
        @include imoon('\e908');

        font-size: .8em;
        display: inline-block;
        padding-right: 5px;

        [dir='rtl'] & {
            @include imoon('\e909');

            padding-right: 0;
            padding-left: 5px;
        }
    }

    &::after {
        content: none !important;
    }
}

// paragraph (copy text)
p {
    font-size: 14px;
    line-height: 20px;
}

// New default text size for all elements
.lg {
    font-size: 16px;
    line-height: 22px;
}

.xl {
    font-size: 18px;
    line-height: 26px;
}

.font-medium {
    font-size: 16px;
    line-height: 20px;
}

.font-large {
    font-size: 18px;
    line-height: 22px;
}

// Optional large formatted text
.big-text {
    font-weight: 200;
    font-size: 16px;
    line-height: 22px;

    @media (min-width: $screen-sm-min) {
        font-size: 20px;
        line-height: 24px;
    }
}

strong {
    font-weight: 700;
}

blockquote {
    position: relative;
    border-left: 0;
    font-weight: 200;
    line-height: 28px;
    padding: 0;

    [dir='rtl'] & {
        border-right: 0;
    }

    @media (min-width: $screen-sm-min) {
        font-size: 28px;
        line-height: 32px;
    }

    &::before {
        @include imoon("\e939");

        position: absolute;
        top: -5px;
        left: -1em;

        [dir='rtl'] & {
            left: auto;
            right: -1em;
        }
    }

    &::after {
        @include imoon("\e93a");

        margin-left: -4px;
        vertical-align: 2px;

        [dir='rtl'] & {
            position: absolute;
            left: -1em;
            margin-right: -4px;
        }
    }
}

// word highlight
mark,
.mark {
    color: $c_darkblue;
    background-color: transparent;
    font-weight: 700;
}

.lightgreen-text {
    color: $c_lightgreen;
}

.lightorange-text {
    color: $c_lightorange;
}

.red-text {
    color: $c_red;
}

.grey-text {
    color: $c_gray75;
}

.black-text {
    color: $c_black;
}

.hint-text {
    font-size: 11px !important;
}

// dots
.dot {
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    background-color: $c_lightgreen;
    height: 13px;
    width: 13px;
    margin-right: 13px;
    margin-top: -1px;

    [dir='rtl'] & {
        margin-right: 0;
        margin-left: 13px;
    }

    .lightgreen-text & {
        background-color: $c_lightgreen;
    }

    .lightorange-text & {
        background-color: $c_lightorange;
    }

    .red-text & {
        background-color: $c_red;
    }
}

.blue-gradient-bg {
    display: inline-block;
    text-transform: uppercase;
    font-size: 12px;
    color: $c_white;
    padding: 8px;
    background: $g_blue;
}

.break-word {
    @include breakword;
}

.no-break-word {
    white-space: nowrap;
}

.hyphenate {
    hyphens: auto;
}

a[href^=tel] {
    color: inherit;
    text-decoration: none;

    @include setDirection(ltr);
}

.ordernumber {
    letter-spacing: normal;
}
