@import 'config/init';

.m-conversion_bar {
    display: none;
    align-items: center;
    justify-content: flex-end;
    flex: 1 0 auto;

    @media (min-width: $screen-md-min) {
        display: flex;
        height: 56px;
    }

    .m-breadcrumb + & {
        @media (max-width: $screen-sm-max) {
            display: none;
        }
    }

    &__link {
        position: relative;
        display: inherit;
        align-items: center;
        color: $c_white;

        &:hover,
        &:focus,
        &:visited {
            color: $c_white;
        }
    }

    &__items {
        display: inherit;
        align-items: center;
        list-style: none;
        margin: 0;

        .js-headerFixed & {
            @media (max-width: $screen-sm-max) {
                position: relative;
            }
        }
    }

    &__item {
        display: inherit;
        padding: 0 10px;

        @media (min-width: $screen-md-min) {
            padding-left: 20px;
        }

        [dir='rtl'] & {
            @media (min-width: $screen-md-min) {
                padding-left: 0;
                padding-right: 20px;
            }
        }

        i {
            font-style: normal;
            font-size: 26px;
            padding-right: 5px;
        }

        .js-headerFixed & {
            @media (min-width: $screen-md-min) {
                padding: 0 10px;
            }
        }

        &--toTop {
            display: none;

            .js-headerFixed & {
                display: inherit;
            }
        }
    }

    &__text {
        display: block;
        padding-left: 10px;

        [dir='rtl'] & {
            padding-left: 0;
            padding-right: 10px;
        }

        @media (max-width: $screen-sm-max) {
            display: none;
        }
    }

    &__notification {
        font-weight: 900;
        position: absolute;
        background-color: $c_white;
        top: -10px;
        right: -10px;
        border: solid 2px $c_darkblue;
        border-radius: 20px;
        color: $c_darkblue;
        width: 20px;
        height: 20px;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9;

        [dir='rtl'] & {
            right: unset;
            left: -10px;
        }
    }

    .m-mainNavigation & {
        display: none;
        height: $main-nav-height;
        order: 2;
        z-index: 1040;

        @media (max-width: $screen-sm-max) {
            display: flex;
        }

        &__item {
            @media (max-width: $screen-sm-max) {
                border-right: solid 1px $c_lightgray25;

                [dir='rtl'] & {
                    border-right: 0;
                    border-left: solid 1px $c_lightgray25;
                }
            }
        }

        &__link {
            display: inherit;
            color: $c_black;
        }

        &__text {
            display: none;

            @media (min-width: $screen-md-min) {
                display: block;
            }
        }

        &__notification {
            background-color: $c_red;
            border: solid 2px $c_white;
            color: $c_white;
        }

        .js-headerFixed & {
            display: flex;
            flex: 1 0 auto;

            @media (min-width: $screen-md-min) {
                position: absolute;
                top: 0;
                right: 35px;

                [dir='rtl'] & {
                    right: 0;
                    left: 35px;
                }
            }
        }

        &__item--toTop {
            @media (min-width: $screen-md-min) {
                padding-left: 10px;
                padding-right: 10px;
                border-left: #e9e9e9 1px solid;
                border-right: #e9e9e9 1px solid;
            }
        }
    }

    .m-shopping-cart & {
        &__notification {
            background-color: $c_lightgray;
            color: $c_white;

            &--red {
                background-color: $c_red;
            }
        }
    }
}

.o-header__mWrapper--whiteBg {
    .m-conversion_bar {
        &__item {
            padding: 0;

            &:not(:first-child) {
                margin-left: 10px;
                padding-left: 5px;
                border-left: 1px solid rgb(230, 230, 230);
            }
        }

        &__link {
            color: $c_black;
        }

        &__notification {
            background-color: $c_bahamablue;
            color: $c_white;
        }

        i {
            padding-right: 0;

            &.icon-Bosch-Ic-mybrand-black,
            &.icon-Bosch-Ic-spareparts2 {
                padding-right: 3px;
            }
        }

        &__text {
            padding-left: 0;
        }

        .m-shopping-cart > .m-conversion_bar__notification {
            background-color: $c_lightgray;

            @media (min-width: $screen-md-min) {
                border-color: $c_lightgray;
            }

            &--red {
                background-color: $c_red;
                border-color: $c_red;
            }
        }
    }
}

.o-header--alt .affix .m-conversion_bar .m-conversion_bar__text {
    display: none;
}

.o-header--alt .affix .m-conversion_bar .m-conversion_bar__item i {
    padding: 0;
}
