@import 'config/init';

%a-readline__spacing {
    min-height: 70px;

    @media (min-width: $screen-sm-min) {
        min-height: 120px;
    }

    @media (min-width: $screen-md-min) {
        min-height: 140px;
    }
}

.a-readline {
    @extend %a-readline__spacing;

    display: none;

    @media (min-width: $screen-lg-min) {
        display: block;
    }

    position: relative;
    z-index: 2;
    width: 1px;
    flex: 1;
    border-left: 1px solid $c_white;
    margin-left: -1px;
    left: 50%;
    transform: translateY(-100%);

    &::before {
        position: absolute;
        content: '';
        height: 16px;
        width: 16px;
        background-color: $c_white;
        border-radius: 50%;
        display: inline-block;
        left: -8px;
    }

    &::after {
        content: '';
        bottom: -40px;
        border-left: 1px solid $c_lightgray;
        margin-left: -1px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;

        @media (min-width: $screen-sm-min) {
            bottom: -60px;
        }

        @media (min-width: $screen-md-min) {
            bottom: -80px;
        }
    }

    &__spacer {
        @extend %a-readline__spacing;

        flex: 1;
    }
}
