@import 'config/init';

.a-price {
    $spans: 9;
    $fs: 36;

    // line-height: $line-height-base;
    color: $c_darkblue;
    font-weight: 700;
    line-height: 1.2;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    letter-spacing: initial;

    &--redesign {
        color: $c_problue;
    }

    &__old {
        font-weight: 400;
        font-size: 24px;
        line-height: inherit;
        text-decoration: line-through;
        color: $c_gray80;
        order: 1;

        @include setDirection(ltr);

        [dir='rtl'] & {
            order: 2;
        }

        &--currency {
            order: 2;

            [dir='rtl'] & {
                order: 1;
            }
        }
    }

    &__label {
        font-weight: 200;
        font-size: 24px;
        color: $c_gray75;

        &--flipped {
            font-weight: inherit;
            line-height: inherit;
            font-size: 28px;
            color: inherit;

            @media (min-width: $screen-sm-min) {
                font-size: 30px;
            }
        }
    }

    &--redesign &__label {
        font-weight: 700;
        color: inherit;
        font-size: 16px;

        @media (min-width: $screen-md-min) {
            font-size: 14px;
        }

        @media (min-width: $screen-md-min) {
            font-size: 20px;
        }
    }

    &__current {
        font-weight: inherit;
        font-size: 28px;
        line-height: inherit;
        color: inherit;
        order: 1;

        [dir='rtl'] & {
            order: 2;
        }

        @include setDirection(ltr);

        @media (min-width: $screen-sm-min) {
            font-size: 36px;

            // ODSUPPORT-3690
            html[lang="id"] & {
                font-size: 30px;
            }
        }

        &--flipped {
            @media (min-width: $screen-sm-min) {
                font-size: 30px;
            }
        }

        &--currency {
            order: 2;

            [dir='rtl'] & {
                order: 1;
            }
        }
    }

    &__vat {
        font-weight: 400;
        font-size: 14px;
        line-height: inherit;
        margin: 10px 0;
        flex: 1 0 100%;
        color: $c_gray80;
    }

    &__offer {
        font-weight: inherit;
        font-size: 14px;
        line-height: inherit;
        flex: 1 0 100%;
        color: inherit;
    }

    &__offer + &__warehouse {
        margin-top: 10px;
    }

    &__warehouse {
        font-weight: inherit;
        font-size: 14px;
        line-height: inherit;
        flex: 1 0 100%;
        color: $c_red;
    }

    &__promo-label {
        text-transform: uppercase;
        font-size: 12px;
        color: $c_white;
        padding: 8px;
        background: $g_blue;
        margin: 10px 0;
    }

    &--white,
    &--white span {
        color: inherit !important;

        @media (min-width: $screen-sm-min) {
            color: $c_white !important;
        }
    }

    &--darkblue {
        color: $c_darkblue;
    }

    &--gray80 {
        color: $c_gray80;
    }

    &--gray75 {
        color: $c_gray75;
    }

    &--block {
        flex: 1 0 100%;
    }

    @for $i from 14 through $fs {
        @if $i % 2 == 0 {
            &--fs#{$i} {
                font-size: #{$i}px;
            }
        }
    }

    @for $i from 1 through $spans {
        &--order-#{$i} {
            order: #{$i};
        }
    }
}
