@import 'config/init';

// Molecule Search Navigation
.m-searchNavigation {
    display: block;
    overflow: hidden;
    width: 50px;
    height: $main-nav-height;
    order: 3;

    @media (min-width: $screen-md-min) {
        transition: width 600ms ease-in-out;
    }

    .o-header:not(.js-headerFixed) & {
        @media (min-width: $screen-md-min) {
            width: 460px;
        }
    }

    .affix & {
        @media (min-width: $screen-md-min) {
            position: absolute;
            right: 0;

            [dir='rtl'] & {
                left: 0;
                right: auto;
            }
        }

        .m-searchNavigation__label {
            display: none;
        }
    }

    &--open {
        position: absolute;
        top: 0;
        left: 15px;
        right: 15px;
        bottom: 0;
        margin: auto;
        width: 100%;

        @media (max-width: $screen-sm-max) {
            width: calc(100% - 15px);
        }

        .o-header:not(.js-headerFixed) & {
            @media (min-width: $screen-md-min) {
                width: 460px;
            }
        }

        @media (min-width: $screen-md-min) {
            left: auto;
            right: 0;

            [dir='rtl'] & {
                left: 15px;
                right: auto;
            }
        }

        @media (min-width: $screen-lg-min) {
            left: auto;
            right: 0;

            [dir='rtl'] & {
                left: 0;
                right: auto;
            }
        }

        .affix & {
            width: 100%;

            @media (max-width: $screen-sm-max) {
                top: 15px;
            }
        }

        ~ .m-conversion_bar {
            @media (max-width: $screen-sm-max) {
                padding-right: 50px;

                [dir='rtl'] & {
                    padding-right: 0;
                    padding-left: 50px;
                }
            }
        }

        .m-searchNavigation__button {
            color: $c_darkblue;
        }
    }

    &__label {
        display: flex;
        align-items: center;
        cursor: text;
        float: right;
        height: 45px;
        width: 0;

        [dir='rtl'] & {
            float: left;
        }

        @media (min-width: $screen-md-min) {
            border-bottom: 1px solid #ccc;
            width: 460px;
        }

        .affix & {
            width: 0;
            border-bottom: 0;
        }

        &Text {
            line-height: normal;
            font-size: 18px;
            font-weight: 200;
            color: #ccc;
        }
    }

    &__form {
        position: relative;
        width: 100%;
        height: 43px;
    }

    &__inputWrapper {
        position: absolute;
        display: inline-block;
        background: $c_white;
        height: $main-nav-height;
        top: 0;
        right: 0;
        bottom: 0;
        left: 100%;
        margin: auto;
        transition: left 600ms ease-in-out;
        z-index: $zindex-search-open;

        [dir='rtl'] & {
            left: 0;
            right: 100%;
            transition: right 600ms ease-in-out;
        }

        &--expanded {
            left: 0;
            transition: left 600ms ease-in-out;
            min-height: auto;

            [dir='rtl'] & {
                right: 0;
            }
        }
    }

    &__button {
        background: transparent;
        border: 0;
        padding: 0;
        outline: none;

        @media (min-width: $screen-md-min) {
            .affix & {
                padding: 0;
            }
        }

        &--submit {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: -30px;
            bottom: 0;
            margin: auto;
            max-height: 26px;

            [dir='rtl'] & {
                left: auto;
                right: -30px;
            }

            &-active {
                left: 0;

                [dir='rtl'] & {
                    left: auto;
                    right: 0;
                }
            }

            i {
                font-size: 26px;
            }
        }

        &--close {
            position: absolute;
            cursor: pointer;
            right: 0;
            opacity: 0;
            height: 45px;
            transition: opacity 600ms ease-out;
            z-index: $zindex-search-open - 1;

            .o-header:not(.o-header--alt) .affix & {
                right: 30px;

                [dir='rtl'] & {
                    left: 30px;
                    right: auto;
                }

                @media (min-width: $screen-md-min) {
                    right: 0;

                    [dir='rtl'] & {
                        left: 0;
                        right: auto;
                    }
                }
            }

            .o-header--alt:not(.js-headerFixed) & {
                right: 15px;

                [dir='rtl'] & {
                    left: 15px;
                    right: auto;
                }

                @media (min-width: $screen-md-min) {
                    right: 0;

                    [dir='rtl'] & {
                        left: 0;
                        right: auto;
                    }
                }
            }

            [dir='rtl'] & {
                right: auto;
                left: 0;
            }

            > i {
                font-size: 26px;

                @media (min-width: $screen-md-min) {
                    font-size: 24px;
                }
            }

            .m-searchNavigation--open & {
                display: block;
                opacity: 1;
                background: 0 0;
                border: 0;
                z-index: $zindex-search-open + 1;

                [dir='rtl'] & {
                    background: 100% 0;
                }
            }
        }
    }

    &__searchField {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 35px;
        margin: auto;
        width: 200px;
        font-size: 16px;
        font-weight: 200;
        line-height: normal;
        border: 0;
        outline: none;
        padding-left: 20px;
        background: transparent;

        [dir='rtl'] & {
            left: auto;
            right: 35px;
        }

        @media (min-width: $screen-sm-min) {
            width: 650px;
        }

        @media (min-width: $screen-md-min) {
            width: 380px;
        }
    }

    &__searchSuggest {
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 35px;
        margin: auto;
        width: 200px;
        background: transparent;
        font-size: 16px;
        font-weight: 200;
        line-height: normal;
        border: 0;
        outline: none;
        padding-left: 20px;
        color: #a3a3a3;

        @media (min-width: $screen-sm-min) {
            width: 650px;
        }

        @media (min-width: $screen-md-min) {
            width: 380px;
        }

        [dir='rtl'] & {
            left: auto;
            right: 35px;
            padding-left: 0;
            padding-right: 20px;
        }
    }
}

.m-searchNavigationResults {
    background: $c_white;
    border: 0;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    left: -15px;
    right: -15px;
    top: 60px;
    position: absolute;
    z-index: $zindex-search;

    @media (min-width: $screen-sm-min) {
        left: auto;
        right: 0;
        margin: 0;
        width: 50%;

        [dir='rtl'] & {
            left: 0;
            right: auto;
        }
    }

    @media (min-width: $screen-md-min) {
        border: solid 1px #d9d9d9;
        box-shadow: 0 15px 15px rgba(0, 0, 0, 0.5);
        top: 45px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: auto;
    }

    &__fly-headline {
        margin-bottom: 10px;
        color: $c_black;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        word-break: break-word;
    }

    .headline.headline__redesign {
        text-transform: none;
    }

    .js-headerFixed & {
        @media (min-width: $screen-sm-min) {
            left: auto;
            right: 15px;
            width: 50%;

            [dir='rtl'] & {
                right: auto;
                left: 15px;
            }
        }

        @media (min-width: $screen-md-min) {
            left: 0;
            right: 0;
            margin: 0 auto;
            width: auto;
            max-width: 1140px;
        }
    }

    &--hidden {
        display: none;
    }

    &__detailed {
        width: 646px;
        flex-grow: 1;
        padding: 25px 32px 28px 32px;

        @media (max-width: $screen-sm-max) {
            display: none;
        }

        .m-searchNavigationResults {
            &__headline-product-suggestion {
                margin-bottom: 25px;
                display: flex;
                justify-content: space-between;
                align-items: baseline;
            }

            &__more-suggestions {
                .headline {
                    margin-bottom: 16px;
                }
            }

            &__items {
                display: flex;
                margin: 0 18px 10px 0;
                padding: 0;

                [dir='rtl'] & {
                    margin: 0 0 10px 18px;
                }

                &__link {
                    margin-right: 0;
                }
            }

            &__itemsWrapper {
                margin-top: 35px;
            }

            &__item {
                list-style: none;
                display: inline-block;
                flex: 1;

                &--image {
                    width: 90px;
                    height: 90px;
                    flex: none;
                    margin-right: 20px;
                    text-align: center;

                    [dir='rtl'] & {
                        margin-right: 0;
                        margin-left: 20px;
                    }

                    > img {
                        max-width: 100%;
                        max-height: 90px;
                    }
                }
            }

            &__teaser {
                padding-bottom: 15px;

                &.hasFlag .img-wrapper {
                    transform: translateY(33px); /* size of flag */
                    margin-bottom: 51px; /* 33px(to compensate transform) + 18px (usual margin) */
                }

                .img-wrapper {
                    margin-bottom: 18px;
                }

                .new-item {
                    position: absolute;
                    z-index: 1000;
                }
            }

            &__headline {
                margin-top: 0;
                margin-bottom: 10px;
                color: $c_black;
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                word-break: break-word;
            }

            &__text {
                margin-bottom: 4px;
                color: $c_black;
                font-size: 16px;
                line-height: 20px;
                word-break: break-word;
                height: 40px;
                overflow: hidden;
            }

            &__price {
                color: $c_darkblue;
                font-size: 16px;
                line-height: 24px;
            }

            &__image {
                max-width: 100%;
            }

            &__links {
                display: flex;
                flex-direction: row;

                .m-searchNavigationResults {
                    &__link {
                        font-size: 16px;
                        font-weight: 200;
                        margin-right: 37px;

                        [dir='rtl'] & {
                            margin-right: 0;
                            margin-left: 37px;
                        }
                    }
                }
            }

            &__linksItems {
                padding-left: 0;
                color: blue;
                list-style-type: none;

                .link {
                    margin-bottom: 10px;
                }

                &__item a {
                    padding-bottom: 6px;
                }
            }
        }
    }

    &__right {
        background-color: $c_lightgray25;
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 30px;

        @media (min-width: $screen-md-min) {
            padding-right: 0;
            padding-left: 0;
            width: 400px;

            [dir='rtl'] & {
                padding-left: 0;
                padding-right: 45px;
            }
        }
    }

    .m-searchNavigationResults {
        &__itemsWrapper {
            display: flex;
            flex-direction: row;
            margin-bottom: 15px;
        }

        &__items {
            padding: 0;
        }

        &__item {
            list-style: none;
            margin-bottom: 24px;

            .strong {
                color: $c_gray;
            }
        }

        &__links {
            padding-left: 0;
        }

        &__link-item {
            list-style-type: none;
            padding: 16px;

            &.active {
                background-color: white;
            }
        }

        &__link {
            display: block;
            height: 100%;
            color: $c_black;

            span {
                color: $c_black;
            }
        }

        &__headline {
            margin-top: 0;
            margin-bottom: 25px;
            height: 48px;
            overflow: hidden;
        }

        &__viewallresults {
            display: flex;
            justify-content: center;
            padding: 0 16px;

            .btn {
                width: 100%;
                border-width: 2px;
                font-weight: 600;
            }
        }

        &__teasers {
            padding: 16px;

            img {
                width: 100%;
            }

            .text {
                margin-bottom: 10px;
            }
        }
    }

    &__header-wrapper {
        width: 100%;
        height: 24px;
        flex-shrink: 0;
    }

    &__header {
        display: flex;
        width: 100%;
        height: 18px;
        background-color: $c_problue;
    }

    &__special {
        display: flex;
        align-items: center;
        height: 100%;
        padding-left: 7px;
        padding-right: 7px;
        background: $c_red;
        border-right: 2px solid $c_white;
        font-weight: bold;
        color: $c_white;
        font-size: 14px;
        text-transform: uppercase;

        [dir='rtl'] & {
            border-left: 2px solid $c_white;
            border-right: none;
        }
    }

    &__intro-picture-wrapper {
        border-bottom: 2px solid $c_problue;

        @media (max-width: $screen-xs-max) {
            display: flex;
            flex-direction: row;
            border-bottom: 2px solid $c_problue;
        }
    }

    &__intro-wrapper {
        @media (max-width: $screen-xs-max) {
            display: flex;
            flex-direction: column;
        }
    }
}

.o-header--alt .m-searchNavigation,
.o-header--alt .m-searchNavigation__form,
.o-header--alt .m-searchNavigation__label,
.o-header--alt .m-searchNavigation__button--close {
    @media (min-width: $screen-md-min) {
        height: 35px;
    }
}

.o-header--alt .m-mainNavigation__col--fixed {
    @media (min-width: $screen-md-min) {
        z-index: 1019;
    }
}

.o-header--alt .m-mainNavigation__col--fixed.m-searchNavigation--opened {
    @media (min-width: $screen-md-min) {
        z-index: 2021;
    }
}

.o-header--alt .m-mainNavigation__col--fixed + .m-mainNavigation__col {
    opacity: 1;
    transition: .6s;
}

.o-header--alt .m-mainNavigation__col--fixed.m-searchNavigation--opened + .m-mainNavigation__col {
    opacity: 0;
}

.o-header--alt:not(.js-headerFixed) .m-searchNavigation {
    @media (min-width: $screen-md-min) {
        width: 200px;
    }
}

.o-header--alt:not(.js-headerFixed) .m-searchNavigation--open {
    @media (min-width: $screen-md-min) {
        width: 100%;
    }
}

.o-header--alt:not(.js-headerFixed) .m-searchNavigation__form {
    @media (min-width: $screen-md-min) {
        position: absolute;
        width: 100%;
        left: 0;

        [dir='rtl'] & {
            left: auto;
            right: 0;
        }
    }
}

.o-header--alt:not(.js-headerFixed) .m-searchNavigation__label {
    @media (min-width: $screen-md-min) {
        justify-content: flex-end;
        align-items: flex-end;
        border: 0;
        margin-right: 10px;

        [dir='rtl'] & {
            margin-left: 10px;
            margin-right: 0;
        }

        span {
            width: 200px;
            padding-bottom: 5px;
            border-bottom: 1px solid #ccc;
        }
    }

    @media (min-width: $screen-lg-min) {
        margin-right: 0;
    }
}

.o-header--alt:not(.js-headerFixed) .m-searchNavigation__button--submit {
    @media (min-width: $screen-lg-min) {
        left: -26px;

        [dir='rtl'] & {
            left: auto;
            right: -26px;
        }
    }
}

.o-header--alt:not(.js-headerFixed) .m-searchNavigation--open .m-searchNavigation__button--submit {
    @media (min-width: $screen-md-min) {
        left: 0;

        [dir='rtl'] & {
            left: auto;
            right: 0;
        }
    }
}

.o-header--alt:not(.js-headerFixed) .m-searchNavigation--open .m-searchNavigation__button--close {
    @media (min-width: $screen-md-min) {
        right: 0;

        [dir='rtl'] & {
            left: 0;
            right: auto;
        }
    }
}

.o-header--alt:not(.js-headerFixed) .m-searchNavigation__searchField,
.o-header--alt:not(.js-headerFixed) .m-searchNavigation__searchSuggest {
    @media (min-width: $screen-md-min) {
        width: 140px;
        padding-left: 5px;
        display: none;

        [dir='rtl'] & {
            padding-left: 0;
            padding-right: 5px;
        }
    }
}

.o-header--alt:not(.js-headerFixed) .m-searchNavigation--open .m-searchNavigation__searchField,
.o-header--alt:not(.js-headerFixed) .m-searchNavigation--open .m-searchNavigation__searchSuggest {
    @media (min-width: $screen-md-min) {
        width: 94%;
        padding-left: 5px;
        display: block;

        [dir='rtl'] & {
            padding-left: 0;
            padding-right: 5px;
        }
    }
}

.o-header--alt .affix {
    .m-searchNavigation,
    .m-searchNavigation__form,
    .m-searchNavigation__label,
    .m-searchNavigation__button--close {
        @media (min-width: $screen-md-min) {
            height: 100%;
        }
    }

    .m-searchNavigation--open {
        @media (max-width: $screen-sm-max) {
            padding-left: 15px;
            padding-right: 15px;
            left: 0;
            right: 0;
        }
    }
}
