@import 'config/init';

.m-comp_tooltip {
    &__close {
        margin-top: 6px;
        margin-right: 3px;
        color: $c_lightgray75;
    }

    p {
        margin-bottom: 0;
    }

    .popover-content {
        .btn {
            margin-top: 20px;
        }
    }
}
