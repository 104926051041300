@import 'config/init';

// Molecule Header Navigation Flyout
.m-header_navigation_flyout {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-height: 0;
    background: $c_white;
    transition: max-height 250ms ease-in, opacity 150ms ease-in-out, margin-top 150ms ease-in-out;
    overflow: hidden;
    z-index: 850;
    margin: 0 auto;

    @media (min-width: $screen-md-min) {
        border-top: 1px solid #e6e6e6;
    }

    @media (max-width: $screen-sm-max) {
        display: none;
    }

    &.open {
        position: relative;
        height: auto;
        max-height: 1800px;

        &--visible {
            opacity: 1;
            z-index: 1050;
        }

        &--hidden {
            position: absolute;
            opacity: 0;
            z-index: 0;
            margin-top: -20px;
        }
    }

    &__header,
    &__close {
        padding-top: 40px;

        .m-header_navigation_flyout__button {
            float: right;

            [dir='rtl'] & {
                float: left;
            }

            > i {
                font-size: 24px;
            }
        }
    }

    &__footer {
        text-align: center;
        margin-top: 50px;
        padding-top: 40px;
        padding-bottom: 40px;

        .m-header_navigation_flyout__button {
            > i {
                font-size: 24px;
            }
        }

        &--acu {
            padding-top: 64px;
            padding-bottom: 0;
        }
    }

    .backlink {
        display: table;
        font-size: 14px;
        margin-bottom: 20px;
    }

    .list-headline {
        display: table;
        margin-bottom: 35px;
    }

    &__itemsWrapper {
        @include clearfix();

        display: none;

        .col-xs-10 {
            padding-right: 0;
        }

        .col-xs-12 {
            padding: 0;
        }

        .m-header_navigation_flyout--products & {
            margin-left: -15px;
            margin-right: -15px;
        }

        .open--visible & {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
    }

    &__items {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        transition: transform cubic-bezier(.38, .04, .35, .96) 450ms;
        transform: translateX(2000px);
        overflow: hidden;

        &.visible {
            position: relative;
            padding: 0;
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
        }

        &.hidden {
            transform: translateX(-2000px);
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            margin: 0;
            padding: 0;

            li.visible {
                display: flex;
                flex-wrap: wrap;
            }
        }

        &--service {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
        }
    }

    &__itemsButton {
        display: flex;
        flex-direction: column;
        position: relative;
        background: $c_lightgray15;
        width: 173px;
        height: 173px;
        margin-bottom: 10px;
        margin-right: 10px;
        padding-top: 10px;

        [dir='rtl'] & {
            margin-right: 0;
            margin-left: 10px;
        }

        > img {
            max-height: 100px;
            margin: auto;
        }

        &:hover {
            background: #dedfe1;

            .link {
                color: $c_black;
            }
        }
    }

    &__imageWrapper {
        margin: auto;

        > img {
            max-height: 100px;
        }
    }

    &__item {
        &--service {
            flex-direction: column;
            justify-content: center;
            width: 165px;
            margin-top: 12px;
            margin-right: 18px;
            position: relative;
        }
    }

    &__caption {
        &--products {
            width: 100%;
            text-align: center;
            font-size: 13px;
            line-height: 15px;
            padding: 0 5px 10px 5px;

            @include breakword;

            &.link::after {
                content: '';
                padding: 0;
            }
        }

        &--service {
            width: 161px;
            margin: 0 auto;

            .link {
                display: block;
                font-size: 14px;
                margin-bottom: 20px;
                line-height: 14px;

                &:first-of-type {
                    font-size: 18px;
                    margin-top: 21px;
                    margin-bottom: 24px;
                    line-height: 20px;
                }

                &:active {
                    color: $c_black;
                }
            }
        }

        &--acu {
            .link {
                display: block;
                font-size: 14px;
                margin-bottom: 16px;
                line-height: 14px;
                color: $c_black;

                &:first-of-type {
                    color: $c_problue;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }

                &:active {
                    color: $c_black;
                }
            }
        }

    }

    &__img {
        &--acu {
            margin-top: 32px;
            margin-bottom: 24px;
            border: 1px solid $c_lightgray50;
            width: 100%;
            height: 94px;
            object-fit: cover;
        }
    }

    &__tradeLinks {
        padding: 10px 0 0;

        li {
            list-style: none;
            margin-bottom: 10px;

            .link {
                display: flex;
                font-size: 14px;
                line-height: 20px;

                &::after {
                    content: '';
                }

                i {
                    font-size: 24px;
                    vertical-align: middle;
                    padding-right: 12px;

                    [dir='rtl'] & {
                        padding-right: 0;
                        padding-left: 12px;
                    }
                }
            }
        }
    }
}

.o-header--alt .m-header_navigation_flyout {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-height: 0;
    background: $c_white;
    transition: max-height 250ms ease-in, opacity 150ms ease-in-out, margin-top 150ms ease-in-out;
    overflow: hidden;
    z-index: 850;
    margin: 0 auto;

    &.open {
        position: relative;
        height: auto;
        max-height: 1800px;

        &--visible {
            opacity: 1;
            z-index: 1050;
        }

        &--hidden {
            position: absolute;
            opacity: 0;
            z-index: 0;
            margin-top: -20px;
        }
    }

    &--mobile {
        display: block;
        position: relative;
        z-index: 1050;
        opacity: 1;
        height: auto;
        max-height: 100%;
    }

    &__header,
    &__close {
        padding-top: 30px;

        .m-header_navigation_flyout__button {
            display: flex;
            align-items: center;
            float: right;

            [dir='rtl'] & {
                float: left;
            }

            > i {
                font-size: 24px;
            }
        }

        @media (max-width: $screen-sm-max) {
            display: none;
        }
    }

    &__itemsWrapper {
        display: none;
    }

    &.open--visible .m-header_navigation_flyout__itemsWrapper {
        display: flex;
        flex-wrap: wrap;

        &--acu {
            display: block;
        }
    }

    &__items {
        position: relative;
        width: 100%;
        padding: 0;
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
        margin-top: 30px;

        @media (max-width: $screen-sm-max) {
            overflow: inherit;
        }

        > .row {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .col--item {
        border-top: 1px solid $c_lightgray;

        &:last-of-type {
            @media (max-width: $screen-sm-max) {
                border-bottom: 1px solid $c_lightgray;
            }
        }

        @media (min-width: $screen-md-min) {
            display: flex;
            border: 0;
        }
    }

    &__item {
        $grid-row-height-xs: 80px;
        $grid-row-height-sm: calc(80px + 20px);

        display: grid;
        grid-template-columns: repeat(4, minmax(min-content, 1fr));
        grid-gap: 16px;
        grid-auto-rows: $grid-row-height-xs;
        align-items: center;

        @media (min-width: $screen-sm-min) {
            grid-auto-rows: $grid-row-height-sm;
            grid-template-columns: repeat(12, minmax(min-content, 1fr));
        }

        @media (min-width: $screen-md-min) {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 24px;
        }

        &:hover {
            color: $c_problue;
        }

        &--service {
            justify-content: flex-start;
            width: initial;
            margin-bottom: 0;
        }

        &__image {
            display: flex;
            align-items: center;
            justify-self: center;

            @media (min-width: $screen-md-min) {
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 1 0 auto;
                width: 100%;
                max-width: 170px;
                height: 96px;
                max-height: 96px;
            }
        }

        &__image img {
            max-height: calc(#{$grid-row-height-xs} / 2 );

            @media (min-width: $screen-sm-min) {
                max-height: calc(#{$grid-row-height-sm} / 2 );
            }

            @media (min-width: $screen-md-min) {
                max-height: 96px;
            }
        }

        &__caption {
            grid-column: 2 / -1;

            @media (min-width: $screen-md-min) {
                text-align: center;
                margin-top: 12px;
            }
        }
    }

    .backlink {
        @media (min-width: $screen-md-min) {
            display: none;
        }
    }

    .m-teaser_boxes .container {
        padding: 0;
    }
}

.o-header--alt.o-header--hoverNav .m-header_navigation_flyout {
    @media (min-width: $screen-md-min) {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 0;
        border-bottom: 1px solid #e6e6e6;
        width: calc(100vw - 17px);
    }

    &.open.open--visible {
        @media (min-width: $screen-md-min) {
            display: block;
        }
    }

    &__footer {
        @media (min-width: $screen-md-min) {
            margin-top: 0;
        }
    }
}
