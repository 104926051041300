.flag-list {
    width: 100%;
    min-height: 30px;
}

.flag {
    display: inline-block;
    padding: 2px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 26px;
    text-transform: uppercase;
    color: $c_white;
    background: $g_blue;
}
