.a-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        position: relative;
        margin-bottom: 12px;
        margin-left: 25px;

        [dir='rtl'] & {
            margin-left: 0;
            margin-right: 25px;
        }

        &::before {
            position: absolute;
            left: -25px;
            content: '■';
            color: #dfdfe0;
            font-size: 15px;

            [dir='rtl'] & {
                left: 0;
                right: -25px;
            }
        }
    }
}
