@import 'config/init';

// Atom Buttons

.btn {
    white-space: normal;
    border-radius: 0;
    font-size: 15px;
    line-height: 1.6;
    padding: 11px 15px;
    text-align: center;
    transition: 0.5s;
    height: auto;
    overflow: hidden;

    // btn-primary
    &.btn-primary {
        background-color: $c_darkblue;
        border-bottom: 2px solid $c_lightblue;
        border-left: none;
        border-right: none;
        border-top: none;
        color: $c_white;
        position: relative;
        transform: translateZ(0);

        &:hover {
            color: $c_white;
            background-color: $c_darkblue;
            border-bottom: 2px solid $c_navyblue;

            &::before {
                transform: scaleY(1);
            }
        }

        &::before {
            background: $c_navyblue50;
            bottom: 0;
            content: "";
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transform-origin: 50% 100%;
            transform: scaleY(0);
            transition-duration: 0.3s;
            transition-property: transform;
            transition-timing-function: ease-out;
            z-index: -1;
        }
    }

    // btn-default
    &.btn-default {
        background: transparent;
        border-radius: 0;
        border: solid $c_white 2px;
        color: $c_white;
        font-size: 15px;

        &:hover {
            background-color: transparent;
            color: rgba($c_white, .5);
            border-color: rgba($c_white, .5);
        }

        &[disabled] {
            color: rgba($c_black, .5);
            border-color: rgba($c_black, .5);
        }
    }

    // btn-red
    &.btn-red {
        background-color: $c_red;
        border-bottom: 2px solid $c_darkred;
        border-left: none;
        border-right: none;
        border-top: none;
        color: $c_white;
        position: relative;
        transform: translateZ(0);

        &:hover {
            color: $c_white;
            background-color: $c_darkred;
            border-bottom: 2px solid $c_red;

            &::before {
                transform: scaleY(1);
            }
        }

        &::before {
            background: $c_darkred;
            bottom: 0;
            content: "";
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transform-origin: 50% 100%;
            transform: scaleY(0);
            transition-duration: 0.3s;
            transition-property: transform;
            transition-timing-function: ease-out;
            z-index: -1;
        }

    }

    // btn-new
    &.btn-new {
        color: $c_white;
        background-color: $c_darkblue;
        position: relative;
        transform: translateZ(0);
        border-left: none;
        border-right: none;
        border-top: none;
        border: none;
        padding: 12px 15px;

        &:hover {
            color: $c_white;
            background-color: $c_darkblue;

            &::before {
                transform: scaleY(1);
            }
        }

        &::before {
            background: $c_problue;
            bottom: 0;
            content: "";
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transform-origin: 50% 100%;
            transform: scaleY(0);
            transition-duration: 0.3s;
            transition-property: transform;
            transition-timing-function: ease-out;
            z-index: -1;
        }
    }

    // btn-ghost
    &.btn-ghost {
        color: $c_darkblue;
        background-color: transparent;
        position: relative;
        border: 1px solid $c_darkblue;
        padding: 12px 15px;

        &:hover {
            color: $c_problue;
            border: 1px solid $c_problue;
        }
    }

    // btn-white
    &.btn-white {
        background: $c_white;
        border-radius: 0;
        border: 2px solid #cbcbcb;
        color: $c_black;
        font-size: 15px;

        &:hover {
            background-color: $c_white;
            border-color: $c_black;
            color: $c_black;
        }
        // loading class for ajax loading
        &.loading {
            position: relative;
            padding-right: 70px;

            [dir='rtl'] & {
                padding-right: 15px;
                padding-left: 70px;
            }

            &::after {
                content: '';
                background-image: url('../img/brand/load-more-animation.gif');
                background-repeat: no-repeat;
                position: absolute;
                top: 50%;
                right: 20px;
                margin-top: -15px;
                width: 30px;
                height: 30px;
                margin-left: 20px;

                [dir='rtl'] & {
                    margin-left: 0;
                    margin-right: 20px;
                    right: auto;
                    left: 20px;
                }
            }
        }
    }

    // btn-grey
    &.btn-grey {
        color: $c_white;
        background-color: #d9d9d9;
        border-radius: 0;
        border-bottom: 2px solid #cccccc;
        font-size: 15px;

        &:hover {
            color: $c_white;
            background-color: #dddddd;
            border-bottom-color: #d9d9d9;
        }
    }

    &-small {
        padding: 10px;

        &-2 {
            font-size: 14px;
            line-height: 20px;
            padding: 5px 20px;
        }
    }

    // btn with icon
    > i {
        font-size: 24px;
        margin-right: 5px;
        vertical-align: middle;

        [dir='rtl'] & {
            float: left;
        }
    }

    // remove blue focus outline from bootstrap
    &.active,
    &:active,
    &.focus,
    &:focus {
        &.focus {
            outline: none;

            &:focus {
                outline: none;
            }
        }

        &:focus {
            outline: none;
        }
    }


}
