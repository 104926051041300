// fix for ios or other touchscreen devices, prevents hover state
// https://stackoverflow.com/a/28058919
// @media (hover: hover) {}

.bba-btn {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px 16px;
    border: 1px solid $c_white;
    border-radius: 0;
    font-weight: 600;
    cursor: pointer;
    transition: 0.5s;
    white-space: normal;

    @media (min-width: $screen-sm-min) {
        display: inline-flex;
    }

    &,
    &:not([href]),
    &:focus,
    &:focus:not([href]),
    &:active,
    &:active:not([href]) {
        background-color: var(--background);
        border-color: var(--border);
        color: var(--text);
    }

    @media (hover: hover) {
        &:hover,
        &:hover:not([href]), {
            background-color: var(--background);
            border-color: var(--border);
            color: var(--text);
        }
    }

    &:focus,
    &:focus:not([href]) {
        box-shadow: none;
    }

    &.disabled,
    &[disabled] {
        cursor: not-allowed;
    }

    // loading class for ajax loading
    &.loading {
        position: relative;
        padding-right: 70px;

        [dir='rtl'] & {
            padding-right: 15px;
            padding-left: 70px;
        }

        &::after {
            content: '';
            background-image: url('../img/brand/load-more-animation.gif');
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            right: 20px;
            margin-top: -15px;
            width: 30px;
            height: 30px;
            margin-left: 20px;

            [dir='rtl'] & {
                margin-left: auto;
                margin-right: 20px;
                right: auto;
                left: 20px;
            }
        }
    }

    i {
        margin-right: 8px;
        color: inherit;
    }

    &--xs {
        font-size: 12px;
        line-height: 18px;
        min-height: 32px;
        padding: 6px 11px;

        i {
            font-size: 16px;
            margin-left: -4px;
            margin-right: 6px;

            [dir='rtl'] & {
                margin-right: -4px;
                margin-left: 6px;
            }
        }
    }

    &--sm {
        font-size: 14px;
        line-height: 20px;
        min-height: 40px;
        padding: 9px 11px;

        i {
            font-size: 20px;
            margin-left: -4px;

            [dir='rtl'] & {
                margin-left: 4px;
            }
        }
    }

    &--md {
        font-size: 16px;
        line-height: 24px;
        min-height: 48px;
        padding: 11px 15px;

        i {
            font-size: 24px;
            margin-left: -4px;

            [dir='rtl'] & {
                margin-left: 4px;
            }
        }
    }

    &--lg {
        font-size: 20px;
        line-height: 28px;
        min-height: 60px;
        padding: 15px 23px;

        i {
            font-size: 28px;
            margin-left: -8px;

            [dir='rtl'] & {
                margin-left: auto;
                margin-right: -8px;
            }
        }
    }

    &--primary {
        --background: #{$c_problue};
        --border: #{$c_problue};
        --text: #{$c_white};

        @media (hover: hover) {
            &:hover,
            &:hover:not([href]) {
                --background: #{$c_darkblue50};
                --border: #{$c_darkblue50};
            }
        }

        &:active,
        &:active:not([href]),
        &:focus,
        &:focus:not([href]) {
            --background: #{$c_darkblue};
            --border: #{$c_darkblue};
        }

        &.disabled,
        &[disabled] {
            --background: #{$c_lightgray};
            --border: #{$c_lightgray};
            --text: #{$c_white};

            @media (hover: hover) {
                &:hover {
                    --background: #{$c_lightgray};
                    --border: #{$c_lightgray};
                    --text: #{$c_white};
                }
            }

            &:focus {
                --background: #{$c_lightgray};
                --border: #{$c_lightgray};
                --text: #{$c_white};
            }
        }
    }

    &--secondary {
        --background: transparent;
        --border: #{$c_problue};
        --text: #{$c_problue};

        @media (hover: hover) {
            &:hover,
            &:hover:not([href]) {
                --border: #{$c_darkblue50};
                --text: #{$c_darkblue50};
            }
        }

        &:active,
        &:active:not([href]),
        &:focus,
        &:focus:not([href]) {
            --border: #{$c_darkblue};
            --text: #{$c_darkblue};
        }

        &.disabled,
        &[disabled] {
            --background: transparent;
            --border: #{$c_lightgray};
            --text: #{$c_lightgray};

            @media (hover: hover) {
                &:hover {
                    --background: transparent;
                    --border: #{$c_lightgray};
                    --text: #{$c_lightgray};
                }
            }

            &:focus {
                --background: transparent;
                --border: #{$c_lightgray};
                --text: #{$c_lightgray};
            }
        }
    }

    &--tertiary {
        --background: transparent;
        --border: transparent;
        --text: #{$c_problue};

        justify-content: start;
        min-height: 0;
        padding: 0;
        border: 0;

        i {
            margin-left: 0;

            [dir='rtl'] & {
                margin-left: auto;
                margin-right: 0;
            }
        }

        @media (hover: hover) {
            &:hover,
            &:hover:not([href]) {
                --text: #{$c_darkblue50};
            }
        }

        &:active,
        &:active:not([href]),
        &:focus,
        &:focus:not([href]) {
            --text: #{$c_darkblue};
        }

        &.disabled,
        &[disabled] {
            --background: transparent;
            --border: transparent;
            --text: #{$c_lightgray};

            @media (hover: hover) {
                &:hover {
                    --background: transparent;
                    --border: transparent;
                    --text: #{$c_lightgray};
                }
            }

            &:focus {
                --background: transparent;
                --border: transparent;
                --text: #{$c_lightgray};
            }
        }
    }

    &--red {
        --background: #{$c_red};
        --border: #{$c_red};
        --text: #{$c_white};

        @media (hover: hover) {
            &:hover,
            &:hover:not([href]) {
                --background: #{$c_lightcoral};
                --border: #{$c_lightcoral};
                --text: #{$c_white};
            }
        }

        &:active,
        &:active:not([href]),
        &:focus,
        &:focus:not([href]) {
            --background: #{$c_maroon};
            --border: #{$c_maroon};
            --text: #{$c_white};
        }

        &.disabled,
        &[disabled] {
            --background: #{$c_lightgray};
            --border: #{$c_lightgray};
            --text: #{$c_white};

            @media (hover: hover) {
                &:hover {
                    --background: #{$c_lightgray};
                    --border: #{$c_lightgray};
                    --text: #{$c_white};
                }
            }

            &:focus {
                --background: #{$c_lightgray};
                --border: #{$c_lightgray};
                --text: #{$c_white};
            }
        }
    }

    &--white {
        --background: #{$c_white};
        --border: #{$c_white};
        --text: #{$c_problue};

        @media (hover: hover) {
            &:hover,
            &:hover:not([href]) {
                --background: #{$c_darkblue50};
                --border: #{$c_darkblue50};
                --text: #{$c_white};
            }
        }

        &:active,
        &:active:not([href]),
        &:focus,
        &:focus:not([href]) {
            --background: #{$c_darkblue};
            --border: #{$c_darkblue};
            --text: #{$c_white};
        }

        &.disabled,
        &[disabled] {
            --background: #{$c_lightgray};
            --border: #{$c_lightgray};
            --text: #{$c_white};

            @media (hover: hover) {
                &:hover {
                    --background: #{$c_lightgray};
                    --border: #{$c_lightgray};
                    --text: #{$c_white};
                }
            }

            &:focus {
                --background: #{$c_lightgray};
                --border: #{$c_lightgray};
                --text: #{$c_white};
            }
        }
    }

    .dark-theme & {
        &--primary {
            --background: #{$c_white};
            --border: #{$c_white};
            --text: #{$c_problue};

            @media (hover: hover) {
                &:hover,
                &:hover:not([href]) {
                    --background: #{$c_darkblue50};
                    --border: #{$c_darkblue50};
                    --text: #{$c_white};
                }
            }

            &:active,
            &:active:not([href]),
            &:focus,
            &:focus:not([href]) {
                --background: #{$c_darkblue};
                --border: #{$c_darkblue};
                --text: #{$c_white};
            }

            &.disabled,
            &[disabled] {
                --background: #{$c_lightgray};
                --border: #{$c_lightgray};
                --text: #{$c_white};

                @media (hover: hover) {
                    &:hover {
                        --background: #{$c_lightgray};
                        --border: #{$c_lightgray};
                        --text: #{$c_white};
                    }
                }

                &:focus {
                    --background: #{$c_lightgray};
                    --border: #{$c_lightgray};
                    --text: #{$c_white};
                }
            }
        }

        &--secondary {
            --background: transparent;
            --border: #{$c_white};
            --text: #{$c_white};

            @media (hover: hover) {
                &:hover,
                &:hover:not([href]) {
                    --border: #{$c_darkblue50};
                    --text: #{$c_darkblue50};
                }
            }

            &:active,
            &:active:not([href]),
            &:focus,
            &:focus:not([href]) {
                --border: #{$c_darkblue};
                --text: #{$c_darkblue};
            }

            &.disabled,
            &[disabled] {
                --background: transparent;
                --border: #{$c_lightgray};
                --text: #{$c_lightgray};

                @media (hover: hover) {
                    &:hover {
                        --background: transparent;
                        --border: #{$c_lightgray};
                        --text: #{$c_lightgray};
                    }
                }

                &:focus {
                    --background: transparent;
                    --border: #{$c_lightgray};
                    --text: #{$c_lightgray};
                }
            }
        }

        &--tertiary {
            --background: transparent;
            --border: transparent;
            --text: #{$c_white};

            @media (hover: hover) {
                &:hover,
                &:hover:not([href]) {
                    --text: #{$c_darkblue50};
                }
            }

            &:active,
            &:active:not([href]),
            &:focus,
            &:focus:not([href]) {
                --text: #{$c_darkblue};
            }

            &.disabled,
            &[disabled] {
                --background: transparent;
                --border: transparent;
                --text: #{$c_lightgray};

                @media (hover: hover) {
                    &:hover {
                        --background: transparent;
                        --border: transparent;
                        --text: #{$c_lightgray};
                    }
                }

                &:focus {
                    --background: transparent;
                    --border: transparent;
                    --text: #{$c_lightgray};
                }
            }
        }
    }

    + .bba-btn {
        margin-top: 16px;
    }

    + *:not(.bba-btn) {
        margin-top: 32px;
    }

    &-group > .bba-btn + .bba-btn {
        margin-top: 0;
        margin-left: 10px;

        [dir='rtl'] & {
            margin-left: auto;
            margin-right: 10px;
        }
    }

    > a {
        color: inherit;
    }

    &--column {
        flex-direction: column;

        span {
            margin-top: 5px;
            margin-left: 0;
        }
    }

    &--label {
        span {
            @include bba('hl5', 'white', 'fw-bold', 'uppercase');

            background-color: $c_red;
            padding: 0 4px;
            margin-top: 0;
            margin-left: 10px;
        }
    }
}
