$c_black: #000;
$c_white: #fff;
$c_boschgray: #293036;
$c_boschgray25: #60707e;
$c_gray: #525f6b;
$c_gray25: #d4d7da;
$c_gray50: #a8afb5;
$c_gray75: #7d8790;

$c_gray80: #808080;
$c_grayD3: #d3d3d4;
$c_gray64: #64727e;
$c_lightgray: #bfc0c2;
$c_lightgray75: #cfd0d1;
$c_lightgray50: #dfdfe0;
$c_lightgray25: #efeff0;
$c_lightgray15: #f5f6f6;
$c_lightgray5: #fafafa;
$c_red: #e20015;
$c_darkred: #a70010;
$c_fuchsia: #b90276;
$c_fuchsia50: #dc80ba;
$c_violet: #50237f;
$c_violet50: #a791bf;
$c_problue: #003b6a;
$c_darkblue: #005691;
$c_darkblue50: #7faac8;
$c_navyblue: #003254;
$c_navyblue50: #1a679c;
$c_navyblue15: #a6c0d4;
$c_lightblue: #008ecf;
$c_lightblue50: #7fc6ef;
$c_lightblue75: #bfe3f3;
$c_turquoise: #00a8b0;
$c_turquoise50: #7fd3d7;
$c_lightgreen: #78be20;
$c_lightgreen50: #bbde8f;
$c_darkgreen: #006249;
$c_darkgreen50: #7fb0a4;
$c_lightorange: #fcaf17;
$c_regalblue: #013b69;
$c_bahamablue: #005691;
$c_monza: #e20014;
$c_paleslate: #c3bec2;

$c_lightcoral: #f4808b;
$c_maroon: #75000b;
