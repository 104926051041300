@import 'config/init';

.m-user-actions {
    + .bsTooltip__itemWrapper {
        max-width: 320px;

        .btn-link {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
