@import 'config/init';

// Molecule Metanavigation
.m-metanavigation {
    padding-left: 20px;

    [dir='rtl'] & {
        padding-left: 0;
        padding-right: 20px;
    }

    &__items {
        font-size: 12px;
        list-style: none;
        margin-bottom: 24px;

        &__item {
            @media (min-width: $screen-md-min) {
                display: inline-block;
            }

            + li {
                @media (min-width: $screen-md-min) {
                    margin-left: 28px;

                    [dir='rtl'] & {
                        margin-left: 0;
                        margin-right: 28px;
                    }
                }
            }

            &--link {
                color: $c_gray;

                &:hover {
                    color: $c_black;
                }
            }
        }
    }

    &__languageselector {
        position: relative;

        &--button {
            background: transparent;
            border: none;
            padding: 0;
            color: $c_gray;

            &:hover {
                color: $c_black;
            }

            &::before {
                @media (min-width: $screen-md-min) {
                    border-left: 1px solid $c_lightgray50;
                    content: '';
                    height: 100%;
                    left: -15px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);

                    [dir='rtl'] & {
                        left: auto;
                        right: -15px;
                    }
                }
            }
        }

        @media (min-width: $screen-md-min) {
            margin-left: 37px;
        }

        &__items {
            line-height: 1.66667;
            margin-top: 5px;
            text-align: inherit;
            left: auto;
            right: 0;

            [dir='rtl'] & {
                left: 0;
                right: auto;
            }

            &__item--link {
                [dir='rtl'] & {
                    float: left;
                }
            }
        }

        &--button i {
            display: inline-block;
            font-size: 12px;
            vertical-align: middle;
        }

        span {
            margin-right: 5px;

            [dir='rtl'] & {
                margin-right: 0;
                margin-left: 5px;
            }

            &:nth-child(2) {
                border-left: 1px solid #e6e6e6;
                padding-left: 5px;
            }

            &[class^="icon-"] {
                font-size: 10px;
            }
        }
    }

    &__mobile {
        background: $c_white none repeat scroll 0 0;
        border-top: 1px solid #e8e8e8;

        a {
            border-top: 1px solid #e8e8e8;
            color: #333;
            display: block;
            font-size: 16px;
            line-height: 50px;
            outline: none;
            text-decoration: none;
            padding: 0 15px;
        }

        ul li > a {
            font-size: 12px;
            line-height: 38px;
            color: #808080;
        }

        .list-inline {
            margin: 0;
        }
    }
}
