@import 'config/init';

.table {
    thead > {
        tr > {
            th {
                border-bottom: solid 3px $c_darkblue;
                font-weight: normal;
                height: auto;
                min-height: 48px;
                vertical-align: middle;
                padding: 10px;

                &:first-child {
                    padding-left: 0;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                height: auto;
                min-height: 48px;
                vertical-align: middle;
                padding: 10px;
                border-top: 1px solid $c_gray25;

                &:nth-child(even) {
                    background: $c_lightgray15;
                }
            }

            &:last-child td {
                border-bottom: solid 1px $c_gray25;
            }
        }
    }

    .main {
        td {
            border-bottom: solid 1px $c_darkblue;
            font-weight: bold;
        }
    }

    .sub {
        td {
            border-bottom: solid 1px $c_lightgray50;
            border-top: none;
        }
    }

    &--fixed {
        table-layout: fixed;
    }

    &--firstCol40 {
        tr > th:first-child,
        tr > td:first-child {
            @media (min-width: $screen-md-min) {
                width: 40%;
            }
        }
    }

    &__product-image {
        @include aspect-ratio(16, 9);

        > img {
            padding: 20px 0;
            margin-left: 0 !important;
        }
    }

    &--archive {
        tbody {
            tr {
                td:nth-child(even) {
                    background: transparent;
                }

                td:nth-child(odd) {
                    background: $c_lightgray15;
                }
            }
        }
    }
}
