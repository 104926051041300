@import "config/init";

// Icon im Header
.m-conversion_bar__item--cart {
    .bsTooltip {
        &__itemWrapper {
            max-width: 400px;
            width: 100%;
        }

        &__inner {
            padding: 24px 16px !important;
        }

        &__content {
            display: flex;
            align-items: center;
        }

        &__button {
            &--close {
                background: transparent;
                border: 0;
                position: absolute;
                top: 10px;
                right: 10px;
                font-size: 18px;

                &::before {
                    @include imoon("\e911");
                }
            }
        }

        &__image {
            flex: 1 0 auto;
            margin-right: 24px;

            .img-responsive {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        &__text-wrapper {
            margin-right: 42px;

            p {
                margin: 0;
            }
        }
    }

    .m-notification_messages {
        padding: 10px 0;
        width: 100%;

        .notification_message {
            display: flex;
            align-items: center;

            &:not(:first-of-type) {
                margin-top: 15px;
            }

            &__content {
                display: flex;
                width: 100%;
                padding: 10px;
                color: $c_lightblue;
                background: rgba($c_lightblue, 0.15);

                p {
                    margin-bottom: 0;
                }
            }

            .icon-notification_message {
                display: flex;
                margin-right: 10px;

                &::before {
                    content: "\e9b5";
                }
            }

            &--info {
                .notification_message__content {
                    color: $c_lightblue;
                    background: rgba($c_lightblue, 0.15);
                }

                .icon-notification_message::before {
                    content: "\e9b5";
                }
            }

            &--success {
                .notification_message__content {
                    color: $c_lightgreen;
                    background: rgba($c_lightgreen, 0.15);
                }

                .icon-notification_message::before {
                    content: "\e9b7";
                }
            }

            &--warning {
                .notification_message__content {
                    color: $c_lightorange;
                    background: rgba($c_lightorange, 0.15);
                }

                .icon-notification_message::before {
                    content: "\e9b9";
                }
            }

            &--error {
                .notification_message__content {
                    color: $c_red;
                    background: rgba($c_red, 0.15);
                }

                .icon-notification_message::before {
                    content: "\e9b3";
                }
            }

            &__close {
                display: flex;
                align-self: flex-start;
                margin-left: 20px;
                cursor: pointer;
            }
        }
    }
}

// Modal
.m-modal-shopping-cart {
    padding-right: 0 !important;

    .modal {
        &-dialog {
            height: 100%;
            width: 100%;
            margin: 0;

            @media (min-width: $screen-lg-min) {
                height: auto;
                margin: 30px auto;
                max-width: 1170px; // container widht
                padding-left: 15px; // container padding
                padding-right: 15px; // container padding
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            border: 0;
            border-radius: 0;
            height: 100%;

            @media (min-width: $screen-lg-min) {
                width: 66.66667%; // 8 Cols
                margin: auto;
            }
        }

        &-header {
            padding: 32px 0;

            .headline {
                font-size: 24px;
                font-weight: 700;
                color: $c_problue;
                margin: 0;
            }

            .close {
                opacity: 1;
            }
        }

        &-body {
            background-color: $c_white;
            overflow-y: auto;
            scrollbar-width: thin;

            @media (min-width: $screen-lg-min) {
                max-height: 60vh;
            }

            /* customize scrollbar css */
            &::-webkit-scrollbar {
                width: 8px;
                background-color:#dfdfe0;
            }

            &::-webkit-scrollbar:horizontal {
                height: 8px;
            }

            &::-webkit-scrollbar-track {
                border: 1px #ffffff solid;
                border-radius: 10px;
                -webkit-box-shadow: 0 0 6px #ffffff inset;
            }

            &::-webkit-scrollbar-thumb {
                background-color:#dfdfe0;
                border: 1px solid #dfdfe0;
                border-radius: 4px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color:#dfdfe0;
                border: 1px solid #dfdfe0;
            }

            &::-webkit-scrollbar-thumb:active {
                background-color:#dfdfe0;
                border: 1px solid #dfdfe0;
            }

            .product {
                &-category-name {
                    font-size: 14px;
                    color: $c_problue;
                    padding-bottom: 4px;
                }

                &-name {
                    font-size: 20px;
                    line-height: 24px;
                    font-weight: 700;
                    color: $c_problue;
                    padding-bottom: 8px;
                }

                &-part-number {
                    font-size: 14px;
                    color: $c_black;
                }

                &-amount {
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 20px;
                    position: absolute;
                    padding-left: 16px;
                }

                &-actions {
                    .form-control,
                    .btn {
                        border: none;
                        background-color: $c_lightgray25;
                        border-bottom: 1px solid $c_lightgray;
                        height: 48px;
                    }

                    .form-control {
                        line-height: 48px;
                        padding: 16px 16px 0;

                        &:focus {
                            box-shadow: none;
                        }
                    }

                    i {
                        color: $c_black;
                    }

                    .input-group {
                        label {
                            position: absolute;
                            top: 4px;
                            left: 16px;
                            z-index: 100;
                            margin: 0;
                            font-weight: normal;
                            font-size: 11px;
                        }
                    }

                    .input-group-btn {
                        .btn {
                            padding: 8px;
                        }

                        .btn-delete {
                            padding-right: 0;
                            padding-left: 16px;
                            background-color: transparent;
                            border-bottom: none;
                        }
                    }
                }
            }

            .basket-item {
                padding: 20px 0 25px 0;
                border-bottom: 1px solid $c_lightgray;

                &__image {
                    max-height: 100px;
                    margin: auto;
                }

                &:last-child {
                    border: 0;
                }
            }
        }

        &-footer {
            position: relative;
            padding-top: 32px;
            margin-top: auto;
            margin-bottom: 24px;
            border: 0;
            box-shadow: 0 -15px 15px -20px rgba(0, 0, 0, 0.8);
            z-index: 1;

            @media (min-width: $screen-lg-min) {
                padding: 32px 0;
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }

    &__summary {
        display: flex;
        flex-direction: column;

        @media (min-width: $screen-sm-min) {
            display: block;
        }

        > [class^="col-"]:first-child {
            order: 2;
        }
    }

    .list-unstyled {
        margin: 0;
        padding-bottom: 24px;

        @media (min-width: $screen-sm-min) {
            padding-bottom: 0;
        }

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 8px;
        }

        li:first-child {
            padding-top: 0;
        }

        li:last-child {
            border-top: 1px solid $c_lightgray;
            padding-top: 12px;
            margin-top: 12px;
        }
    }

    .price {
        display: inline-flex;
        font-size: 14px;
        font-weight: 700;

        &__currency {
            padding-left: 4px;
        }

        &--alt .price__value {
            order: 1;
        }

        &--alt .price__currency {
            order: 0;
            padding-left: 0;
            padding-right: 4px;
        }

        &--total,
        &--subtotal {
            color: $c_darkblue;
        }

        &--product {
            font-size: 20px;
        }

        &--total {
            font-size: 20px;

            @media (min-width: $screen-sm-min) {
                font-size: 24px;
            }
        }

        &--subtotal {
            font-size: 16px;
            line-height: 22px;
        }
    }

    .price-label {
        &--subtotal {
            font-size: 16px;
            line-height: 22px;
        }

        &--total {
            @media (min-width: $screen-lg-min) {
                font-size: 24px;
            }

            small {
                font-size: 12px;
            }
        }
    }

    .flag-warehouse,
    .new-item {
        margin-bottom: 12px;
    }
}

.t-productdetailpage--eshop .m-modal-shopping-cart.modal,
.t-productdetailpage--eshop .modal#ajaxModal {
	z-index: 1049;
}

.t-productdetailpage--eshop .modal-backdrop {
    z-index: 1048;
}

.t-productdetailpage--eshop .bsTooltip__itemWrapper.popover {
    z-index: 1031;
}

.t-productdetailpage--eshop .m-selection-card {
    z-index: 990;
}
