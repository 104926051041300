@import 'config/init';

// Atom BBAText

.bba {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0;

    &--problue {
        color: $c_problue;
    }

    &--problue-w50 {
        color: $c_darkblue50;
    }

    &--darkblue {
        color: $c_darkblue;
    }

    &--red {
        color: $c_red;
    }

    &--white {
        color: $c_white;
    }

    &--darkgrey {
        color: $c_gray;
    }

    &--lightgrey {
        color: $c_lightgray;
    }

    &--fw-medium {
        font-weight: 600;
    }

    &--fw-bold {
        font-weight: 700;
    }

    &--uppercase {
        text-transform: uppercase;
    }

    &--large {
        font-size: 40px;
        line-height: 40px;
        font-weight: 900;
        letter-spacing: -0.05em;
        text-transform: uppercase;

        @media (min-width: $screen-md-min) {
            font-size: 60px;
            line-height: 60px;
        }
    }

    &--hl1 {
        font-size: 32px;
        line-height: 40px;
        font-weight: 900;
        letter-spacing: -0.02em;
        text-transform: uppercase;

        @media (min-width: $screen-md-min) {
            font-size: 48px;
            line-height: 48px;
            letter-spacing: -0.05em;
        }
    }

    &--hl2 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 900;
        letter-spacing: -0.02em;
        text-transform: uppercase;

        @media (min-width: $screen-md-min) {
            font-size: 36px;
            line-height: 42px;
        }
    }

    &--hl3 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        letter-spacing: -0.02em;

        @media (min-width: $screen-md-min) {
            font-size: 28px;
            line-height: 34px;
        }
    }

    &--hl4 {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;

        @media (min-width: $screen-md-min) {
            font-size: 24px;
            line-height: 28px;
            letter-spacing: -0.02em;
        }
    }

    &--hl5 {
        font-size: 16px;
        line-height: 22px;

        @media (min-width: $screen-md-min) {
            font-size: 20px;
            line-height: 24px;
        }
    }

    &--big {
        font-size: 16px;
        line-height: 22px;

        @media (min-width: $screen-md-min) {
            font-size: 16px;
            line-height: 22px;
        }
    }

    &--small {
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.02em;

        @media (min-width: $screen-md-min) {
            font-size: 12px;
            line-height: 18px;
        }
    }
}
